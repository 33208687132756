import React, { useState, useEffect } from 'react';
import './Video.scss';
import { fabric } from '../fabric/dist/fabric.js';
import { gradToStateObj, stateObjToGrad } from './../utils/gradientTools';
import { Tabs, Tab } from './Tabs';
import { ChromePicker } from 'react-color';
import GradientPicker from './GradientPicker';
import __ from './../utils/translation';
import $ from "jquery";
import InputAmount from './InputAmount';

import { ReactComponent as IconTrash } from '../icons/trash.svg';
import { ReactComponent as IconPlay } from '../icons/play.svg';
import { ReactComponent as IconAdd } from '../icons/add.svg';

import { ReactComponent as IconAnimation } from '../icons/shoe.svg';

import { ReactComponent as IconHair } from '../icons/hair.svg';
import { ReactComponent as IconArrow } from '../icons/arrow.svg';
import { ReactComponent as IconMask } from '../icons/mask.svg';
import { ReactComponent as IconGlasses } from '../icons/glasses-svgrepo-com.svg';
import { ReactComponent as IconHeadphones } from '../icons/headphones.svg';
import { ReactComponent as IconHat } from '../icons/hat.svg';
//import { ReactComponent as IconHat } from '../icons/hat.svg';


const Material = ({ canvas, activeSelection }) => {

    const [drawSettings, setDrawSettings] = useState({
        duration: 1,
        time: 10,
        brilho: 0,
        metal: 0,
        scale: 0,
        color: 'rgba(255, 255, 255, 0)',
        camisa: true,
        short: true,
        meia: true,
        corpo: false,
        sapato: false,
        cabelo: false,
        mascara: false,
        oculos: false,
        hats: false,
        fone: false,
        polchete: false,
        morph: true,
    })

    window.type_video = "auto";

    $("body").on('click', '.custom-type', function () {
        /*$(".custom-type").css("text-decoration", "none");
        $(this).css("text-decoration", "underline");

        $(".custom").css("display", "none");
        $("#" + $(this).data("type")).css("display", "block");*/

        $(".personalize").css("display", "none");
        $("." + $(this).data("type")).css("display", $(this).data("display"));
    });

    const handleBodyState = (attribute, value) => {
        setDrawSettings({...drawSettings,
            attribute: value
        })
    
        window['stateBody'](attribute, value);
    }

    const handleMorphState = (value) => {
        setDrawSettings({...drawSettings,
            attribute: value
        })
    
        window['stateMorph'](value);
    }

    return (
        <>
            <div>
            <p className="title">{__('PERSONALIZE')}</p>
                {/**<p>{__(`Para gerar o vídeo é necessário a instalação de um plugin: `)}
                    <a target="_blank" href='https://chrome.google.com/webstore/detail/canvas-capture/pnhaaddlgbpchligciolcdjgndcpelee/related'>link</a>
                </p> */}

                {/**<div className='options'>
                    <p data-type="personalize-items" data-display="flex" className="subtitle custom-type">{__('ITEMS')}</p>
                    <p data-type="personalize-states" data-display="block" className="subtitle custom-type">{__('STATES')}</p>
                </div> */}

                <div className='options personalize personalize-items'>
                    <div className="input-select">
                        <div className="select" id='select-item'>
                            <div className="active-option" id="active-option-item">All</div>
                            <div className="list" id='list-items'>
                                <div data-type="all" className='option option-item'>All</div>
                                <div data-type="gola" className="option option-item">Collars</div>
                                <div data-type="shoe" className="option option-item">Shoes</div>
                                <div data-type="hair" className="option option-item">Hairs</div> 
                                <div data-type="mask" className="option option-item">Masks</div>
                                <div data-type="glasses" className="option option-item">Glasses</div>
                                <div data-type="hats" className='option option-item'>Hats</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="parts-panel" className='personalize personalize-states'>

                {/**<div className="setting">
                    <div className="label">{__('Carregar Corpo')}</div><div className="function">
                    <input type="checkbox" value="true" defaultChecked={drawSettings.keepAspectRatio}
                        onChange={(e) => handleBodyState(e.target.checked ) } />
                    </div>
                </div> */}

                <div className='expand states' data-id="camisa">
                    <span>Shirt</span>
                    <input type="checkbox" value="true" defaultChecked={drawSettings.camisa}
                        onChange={(e) => handleBodyState('camisa', e.target.checked ) } />
                </div>
                <div className='expand states' data-id="short">
                    <span>Short</span>
                    <input type="checkbox" value="true" defaultChecked={drawSettings.short}
                        onChange={(e) => handleBodyState('short', e.target.checked ) } />
                </div>
                <div className='expand states' data-id="corpo" >
                    <span>Body</span>
                    <input id="state-main-body" type="checkbox" value="false" defaultChecked={drawSettings.corpo}
                        onChange={(e) => handleBodyState('corpo', e.target.checked ) } />
                </div>

                <div className='expand states' data-id="morph" >
                    <span>Facial Expressions</span>
                    <input id="state-main-expressions" type="checkbox" value="true" defaultChecked={drawSettings.morph}
                        onChange={(e) => handleMorphState(e.target.checked ) } />
                </div>

                <div className='expand states' data-id="meia">
                    <span>Socks</span>
                    <input id="state-main-socks" type="checkbox" value="true" defaultChecked={drawSettings.meia}
                        onChange={(e) => handleBodyState('meia', e.target.checked ) } />
                </div>
                
                {/**<div id="corpo" className='expand-child'>
                    <div className='corpo' data-name="body">
                        <h1>C</h1>
                        <span>Humano</span>
                    </div>
                    <div className='corpo' data-name="android1">
                        <h1>C</h1>
                        <span>Android 1 </span>
                    </div>
                    <div className='corpo' data-name="android2">
                        <h1>C</h1>
                        <span>Android 2</span>
                    </div>
                    <div className='corpo' data-name="android3">
                        <h1>C</h1>
                        <span>Android 3</span>
                    </div> 
                </div>*/}

                <div className='expand states' data-id="shoes" data-opened="false">
                    <span>Shoes</span>
                    <input id="state-main-shoes" type="checkbox" value="false" defaultChecked={drawSettings.sapato}
                        onChange={(e) => handleBodyState('sapato', e.target.checked ) } />
                </div>

                <div className='expand states' data-id="hair" data-opened="false">
                    <span>Hairs</span>
                    <input id="state-main-hair" type="checkbox" value="false" defaultChecked={drawSettings.cabelo}
                        onChange={(e) => handleBodyState('cabelo', e.target.checked ) } />
                </div>

                <div className='expand masksP states' data-id="mask" data-opened="false">
                    <span>Masks</span>
                    <input id="state-main-mask" type="checkbox" value="false" defaultChecked={drawSettings.mascara}
                        onChange={(e) => handleBodyState('mask', e.target.checked ) } />
                </div>
                
                <div className='expand glassesP states' data-id="glasses" data-opened="false">
                    <span>Glasses</span>
                    <input id="state-main-glasses" type="checkbox" value="false" defaultChecked={drawSettings.oculos}
                        onChange={(e) => handleBodyState('oculos', e.target.checked ) } />
                </div>

                <div className='expand states' data-id="hats" data-opened="false">
                    <span>Hats</span>
                    <input id="state-main-hat" type="checkbox" value="false" defaultChecked={drawSettings.hats}
                        onChange={(e) => handleBodyState('hats', e.target.checked ) } />
                </div>
            </div>

            <div id="parts-thumbs" className='personalize personalize-items'>
                    {/**FUTEBOL MASCULINO */}
                    <div className='gola gola-male-futebol gola-female-futebol' data-name="gola_o_curta"
                     data-t="0" data-b="1" data-p="0" data-pu="0" data-pd="1" id="soccer-male-1"></div>

                    <div className='gola gola-male-futebol gola-female-futebol' data-name="gola_o_longa"
                    data-t="0" data-b="0" data-p="0" data-pu="0" data-pd="1"  id="soccer-male-2"></div>

                    <div className='gola gola-male-futebol gola-female-futebol' data-name="gola_padre"
                    data-t="0" data-b="1" data-p="0" data-pu="0" data-pd="1" id="soccer-male-3"></div>

                    <div className='gola gola-male-futebol' data-name="gola_redonda"
                    data-t="0" data-b="1" data-p="0" data-pu="0" data-pd="1" id="soccer-male-4"></div>

                    <div className='gola gola-male-futebol gola-female-futebol' data-name="gola_v_curta"
                    data-t="0" data-b="1" data-p="0" data-pu="0" data-pd="1"  id="soccer-male-5"></div>

                    <div className='gola gola-male-futebol gola-female-futebol' data-name="gola_v_longa"
                    data-t="0" data-b="0" data-p="0" data-pu="0" data-pd="1"  id="soccer-male-6"></div>

                    {/**BASQUETE MASCULINO */}
                    <div className='gola gola-male-basquete gola-female-basquete' data-name="gola_o"
                    data-t="1" data-b="1" data-p="0" data-pu="0" data-pd="1">
                        <h1>C</h1>
                        <span>Collar O</span>
                    </div>
                    <div className='gola gola-male-basquete gola-female-basquete' data-name="gola_v"
                    data-t="1" data-b="1" data-p="0" data-pu="0" data-pd="1">
                        <h1>C</h1>
                        <span>Collar V</span>
                    </div>
                    {/**VOLEI MASCULINO */}
                    <div className='gola gola-male-volei gola-female-volei' data-name="gala-v-manga"
                    data-t="0" data-b="1" data-p="0" data-pu="0" data-pd="1">
                        <h1>C</h1>
                        <span>Sleeve Collar V</span>
                    </div>
                    <div className='gola gola-male-volei' data-name="gola-redonda-manga"
                    data-t="0" data-b="1" data-p="0" data-pu="0" data-pd="1">
                        <h1>C</h1>
                        <span>Sleeve Collar O</span>
                    </div>
                    <div className='gola gola-male-volei' data-name="gola-redonda-sem-manga"
                    data-t="1" data-b="1" data-p="0" data-pu="0" data-pd="1">
                        <h1>C</h1>
                        <span>No Sleeve Collar O</span>
                    </div>
                    <div className='gola gola-male-volei gola-female-volei' data-name="gola-v-sem-manga"
                    data-t="1" data-b="1" data-p="0" data-pu="1" data-pd="1">
                        <h1>C</h1>
                        <span>No Sleeve Collar V</span>
                    </div>
                    <div className='gola gola-female-volei' data-name="vestido"
                    data-t="1" data-b="1" data-p="0" data-pu="1" data-pd="1">
                        <h1>D</h1>
                        <span>Dress</span>
                    </div>
                    {/**ABRIGO MASCULINO */}
                    <div className='gola gola-male-abrigo gola-female-abrigo' data-name="zipper_off"
                    data-t="0" data-b="0" data-p="0" data-pu="0" data-pd="0">
                        <h1>C</h1>
                        <span>Zipper off</span>
                    </div>
                    <div className='gola gola-male-abrigo gola-female-abrigo' data-name="zipper_on"
                    data-t="0" data-b="0" data-p="0" data-pu="0" data-pd="0">
                        <h1>C</h1>
                        <span>Zipper On</span>
                    </div>
                    {/**CICLISMO MASCULINO */}
                    <div className='gola gola-male-ciclismo gola-female-ciclismo' data-name="curta"
                    data-t="0" data-b="1" data-p="0" data-pu="0" data-pd="1">
                        <h1>C</h1>
                        <span>Short sleeve</span>
                    </div>
                    <div className='gola gola-male-ciclismo gola-female-ciclismo' data-name="longa"
                    data-t="0" data-b="0" data-p="0" data-pu="0" data-pd="1">
                        <h1>L</h1>
                        <span>Long sleeve</span>
                    </div>
                    {/**SURF MASCULINO */}
                    <div className='gola gola-male-surf' data-name="bermuda"
                    data-t="1" data-b="1" data-p="1" data-pu="1" data-pd="1">
                        <h1>B</h1>
                        <span>Shorts</span>
                    </div>
                    <div className='gola gola-male-surf gola-female-surf' data-name="wetsuit"
                    data-t="0" data-b="0" data-p="1" data-pu="0" data-pd="0">
                        <h1>W</h1>
                        <span>Wetsuit</span>
                    </div>
                    <div className='gola gola-female-surf' data-name="maio">
                        <h1>S</h1>
                        <span>Swimsuit</span>
                    </div>
                    {/**TENIS MASCULINO */}
                    <div className='gola gola-male-tenis' data-name="gola_o"
                    data-t="0" data-b="1" data-p="0" data-pu="0" data-pd="1">
                        <h1>O</h1>
                        <span>Collar O</span>
                    </div>
                    <div className='gola gola-female-tenis' data-name="gola_o"
                    data-t="1" data-b="1" data-p="0" data-pu="0" data-pd="1">
                        <h1>D</h1>
                        <span>Dress</span>
                    </div>
                    <div className='gola gola-male-tenis gola-female-tenis' data-name="gola_v"
                    data-t="0" data-b="1" data-p="0" data-pu="1" data-pd="1">
                        <h1>V</h1>
                        <span>Collar V</span>
                    </div>
                    {/**PESCA MASCULINO */}
                    <div className='gola gola-male-pesca gola-female-pesca' data-name="capuz-zipper-total"
                    data-t="0" data-b="0" data-p="0" data-pu="0" data-pd="0">
                        <h1>C</h1>
                        <span>Hood Zipper total</span>
                    </div>
                    <div className='gola gola-male-pesca gola-female-pesca' data-name="zipper-pequeno"
                    data-t="0" data-b="0" data-p="0" data-pu="0" data-pd="0">
                        <h1>V</h1>
                        <span>No hood Zipper small</span>
                    </div>
                    <div className='gola gola-male-pesca gola-female-pesca' data-name="zipper-total"
                    data-t="0" data-b="0" data-p="0" data-pu="0" data-pd="0">
                        <h1>V</h1>
                        <span>No hood Zipper total</span>
                    </div>
                    {/**CASUAL MASCULINO */}
                    <div className='gola gola-male-casual1' data-name="casual1"
                    data-t="0" data-b="0" data-p="0" data-pu="0" data-pd="0">
                        <h1>C</h1>
                        <span>Casual 1</span>
                    </div>
                    <div className='gola gola-male-casual1' data-name="casual2"
                    data-t="0" data-b="1" data-p="0" data-pu="0" data-pd="0">
                        <h1>V</h1>
                        <span>Casual 2</span>
                    </div>
                    <div className='gola gola-male-casual1' data-name="casual3"
                    data-t="0" data-b="0" data-p="0" data-pu="0" data-pd="0">
                        <h1>V</h1>
                        <span>Casual 3</span>
                    </div>
                    {/**CASUAL FEMININO */}
                    <div className='gola gola-female-casual1' data-name="casual1"
                    data-t="0" data-b="0" data-p="0" data-pu="0" data-pd="0">
                        <h1>C</h1>
                        <span>Casual 1</span>
                    </div>
                    <div className='gola gola-female-casual1' data-name="casual2"
                    data-t="0" data-b="1" data-p="0" data-pu="1" data-pd="1">
                        <h1>V</h1>
                        <span>Casual 2</span>
                    </div>
                    <div className='gola gola-female-casual1' data-name="casual3"
                    data-t="0" data-b="1" data-p="0" data-pu="0" data-pd="1">
                        <h1>V</h1>
                        <span>Casual 3</span>
                    </div>
                    {/**ACADEMIA */}
                    {/**<div className='gola gola-female-academia' data-name="regata"
                    data-t="1" data-b="1" data-p="0" data-pu="0" data-pd="1">
                        <h1>R</h1>
                        <span>Regatta</span>
                    </div>
                    <div className='gola gola-female-academia' data-name="top"
                    data-t="1" data-b="1" data-p="0" data-pu="0" data-pd="1">
                        <h1>T</h1>
                        <span>Top</span>
                    </div> */}
                    {/**MOTOCROSS */}
                    <div className='gola gola-male-motocross' data-name="normal_A"
                    data-t="0" data-b="0" data-p="0" data-pu="0" data-pd="0">
                        <h1>1</h1>
                        <span>Option 1</span>
                    </div>
                    <div className='gola gola-male-motocross' data-name="normal_B"
                    data-t="0" data-b="0" data-p="0" data-pu="0" data-pd="0">
                        <h1>2</h1>
                        <span>Option 2</span>
                    </div>

                    <div className='shoe' data-shoe="1" id="shoe-1"></div>
                    <div className='shoe' data-shoe="2" id="shoe-2"></div>
                    <div className='shoe' data-shoe="3" id="shoe-3"></div>
                    <div className='shoe' data-shoe="4" id="shoe-4"></div>
                    <div className='shoe' data-shoe="5" id="shoe-5"></div>
                    <div className='shoe' data-shoe="6" id="shoe-6"></div>
                    <div className='shoe' data-shoe="7" id="shoe-7"></div>
                    <div className='shoe' data-shoe="8" id="shoe-8"></div>
                    <div className='shoe' data-shoe="9" id="shoe-9"></div>
                    <div className='shoe' data-shoe="10" id="shoe-10"></div>
                    <div className='shoe' data-shoe="11" id="shoe-11"></div>
                    <div className='shoe' data-shoe="12" id="shoe-12"></div>
                    <div className='shoe' data-shoe="13" id="shoe-13"></div>
                    <div className='shoe' data-shoe="14" id="shoe-14"></div>
                    <div className='shoe' data-shoe="15" id="shoe-15"></div>
                    <div className='shoe notFemale' data-shoe="16" id="shoe-16"></div>
                    <div className='shoe notFemale' data-shoe="17" id="shoe-17"></div>
                    <div className='shoe notFemale' data-shoe="18" id="shoe-18"></div>
                    <div className='shoe notFemale' data-shoe="19" id="shoe-19"></div>
                    <div className='shoe notFemale' data-shoe="20" id="shoe-20"></div>
                    <div className='shoe notFemale' data-shoe="21" id="shoe-21"></div>
                    <div className='shoe notFemale' data-shoe="22" id="shoe-22"></div>
                    <div className='shoe notFemale' data-shoe="23" id="shoe-23"></div>
                    <div className='shoe notFemale' data-shoe="24" id="shoe-24"></div>
                    <div className='shoe notFemale' data-shoe="25" id="shoe-25"></div>
                    <div className='shoe notFemale' data-shoe="26" id="shoe-26"></div>

                    <div className='hair' data-hair="1" id="hair-1"></div>
                    <div className='hair' data-hair="2" id="hair-2"></div>
                    <div className='hair' data-hair="4" id="hair-4"></div>
                    <div className='hair' data-hair="5" id="hair-5"></div>
                    <div className='hair' data-hair="6" id="hair-6"></div>
                    <div className='hair' data-hair="7" id="hair-7"></div>
                    <div className='hair' data-hair="9" id="hair-9"></div>
                    <div className='hair' data-hair="10" id="hair-10"></div>

                    <div className='mask' data-mask="1" id="mask-1"></div>
                    <div className='mask' data-mask="2" id="mask-2"></div>
                    <div className='mask' data-mask="3" id="mask-3"></div>
                    <div className='mask' data-mask="4" id="mask-4"></div>
                    <div className='mask' data-mask="5" id="mask-5"></div>
                    <div className='mask' data-mask="6" id="mask-6"></div>
                    <div className='mask' data-mask="7" id="mask-7"></div>
                    <div className='mask' data-mask="8" id="mask-8"></div>
                    <div className='mask' data-mask="9" id="mask-9"></div>
                    <div className='mask' data-mask="10" id="mask-10"></div>
                    <div className='mask' data-mask="11" id="mask-11"></div>
                    <div className='mask' data-mask="12" id="mask-12"></div>
                    <div className='mask' data-mask="13" id="mask-13"></div>
                    <div className='mask' data-mask="14" id="mask-14"></div>
                    <div className='mask' data-mask="15" id="mask-15"></div>
                    <div className='mask' data-mask="16" id="mask-16"></div>

                    <div className='glasses' data-glasses="1" id="glass-1"></div>
                    <div className='glasses' data-glasses="2" id="glass-2"></div>
                    <div className='glasses' data-glasses="3" id="glass-3"></div>
                    <div className='glasses' data-glasses="4" id="glass-4"></div>
                    <div className='glasses' data-glasses="5" id="glass-5"></div>
                    <div className='glasses' data-glasses="6" id="glass-6"></div>
                    <div className='glasses' data-glasses="7" id="glass-7"></div>
                    <div className='glasses' data-glasses="8" id="glass-8"></div>

                    <div className='hats' data-hats="1" id="hat-1"></div>
                    <div className='hats' data-hats="2" id="hat-2"></div>
                    <div className='hats' data-hats="4" id="hat-4"></div>
                    <div className='hats' data-hats="5" id="hat-5"></div>
            </div>
            
        </>
    )
}

export default Material
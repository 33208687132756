import React, { useRef } from 'react';
import './UploadSettings.scss';

import { fabric } from '../fabric/dist/fabric.js';

import __ from './../utils/translation';

import { ReactComponent as IconSadSmiley } from './../icons/sad-smiley.svg';

import $ from "jquery";

const UploadSettings = ({ canvas }) => {

  const inputFile = useRef(null)
  const dropArea = useRef(null)


  // check if HTML5 File API is supported by the browser
  if (!window.File && !window.FileReader && !window.FileList && !window.Blob) {
    return (
      <div className="file-upload no-support">
        <p>{__(`Your browser doesn't support file upload. Please upgrade to a modern browser, for example:`)} <a href="https://www.google.com/chrome/" target="_blank" rel="noopener noreferrer">Chrome!</a></p>
        <p><IconSadSmiley /></p>
      </div>
    )
  }


  const processFiles = (e, files) => {
    e.preventDefault()

    dropArea.current.style.backgroundColor = '#fff'

    const allowedTypes = ['image/jpeg', 'image/png', 'image/svg+xml', 'application/pdf']

    for (let file of files) {
      // check type
      if (!allowedTypes.includes(file.type)) continue

      let reader = new FileReader()

      // handle svg
      /*if (file.type === 'image/svg+xml'  || file.type === 'application/pdf') {
        //var svg;
        reader.onload = (f) => {
          fabric.loadSVGFromString(f.target.result, function(objects, options) {
            const svg = fabric.util.groupSVGElements(objects, options);
            // canvas scale to svg
            //canvas.setWidth(svg.width);
            //canvas.setHeight(svg.height);
            //or svg scale to canvas
            svg.scaleToHeight(canvas.height);

            //Glitch, need to do this twice to make things right
            fabric.loadSVGFromString(f.target.result,
                (objects, options) => {
                    var group = new fabric.Group(objects, options);
                    group.scaleToHeight(canvas.getHeight());
                    var items = group._objects;
                    group._restoreObjectsState();
            
                    for (let i = 1; i < items.length; i++) {
                      items[i].lockMovementX = true;
                      items[i].lockMovementY = true;
                      items[i].lockScalingX = true;
                      items[i].lockScalingY = true;
                      items[i].lockRotation = true;
                      items[i].hasControls = false;
                      items[i].default = true;

                      canvas.add(items[i]);
                    }
                    
                    canvas.renderAll();
                });
            
          });
        }
        reader.readAsText(file)
        continue
      }*/

      // handle image, read file, add to canvas
      reader.onload = (f) => {
        //console.log(f.target.result);

        //var files = f.target.file
        //var filename = files[0].name
        //var extension = files.type

        //console.log(f)

        console.log(file)

        $("#image-holder").append("<div data-type='"+file.type+"' class='img-holder' data-value='"+URL.createObjectURL(file)+"' style='background-image: url("+f.target.result+")'><div class='remove_img_holder'>X</div><div class='crop_img_holder'>C</div></div>")

        /*fabric.Image.fromURL(f.target.result, (img) => {
          img.set({left: 0, top: 0})
          img.scaleToHeight(300)
          img.scaleToWidth(300)
          canvas.add(img)

          canvas.renderAll()
          canvas.trigger('object:modified')
        })*/
      }

      

      reader.readAsDataURL(file)
    }
  }


  return (
    <>
      <p className="title">{__('UPLOAD IMAGEM')}</p>

      <div className="file-upload">

        <p>{__(`O upload do arquivo ocorre por meio da API de arquivo HTML5, portanto, não usamos o servidor para armazenar imagens do usuário. Você também pode copiar e colar uma imagem da área de transferência!`)}</p>

        <div className="drop" ref={dropArea} onClick={() => inputFile.current.click()}
          onDrop={(e) => processFiles(e, e.dataTransfer.files)}
          onDragOver={(e) => {
            e.preventDefault()
            dropArea.current.style.backgroundColor = '#ddd'
          }}
          onDragLeave={(e) => {
            e.preventDefault()
            dropArea.current.style.backgroundColor = '#fff'
          }}>
          <div className="info">{__('Drag & drop files')}<br />{__('or click to browse.')}<br />{__('JPG, PNG or SVG only!')}</div>
        </div>

        <input type="file" onChange={(e) => processFiles(e, inputFile.current.files)} multiple
          ref={inputFile} style={{display: 'none'}} />

      </div>

      <p>Lista de Imagens</p>

      <div id="image-holder">

      </div>
    </>
  )

}


export default UploadSettings

import React, { useEffect } from 'react';
import { fabric } from '../fabric/dist/fabric.js';

import __ from './../utils/translation';
import copyPaste from './../utils/copyPaste';
import lineDrawing from './../utils/lineDrawing';
import pathDrawing from './../utils/pathDrawing';
import textBoxDrawing from './../utils/textBoxDrawing';
import demoContent from './../utils/demoContent';

import './FabricCanvas.scss';
import $ from "jquery";


const FabricCanvas = (props) => {

  // init fabric canvas once
  useEffect(() => {

    window['updateFabricCanvas'] = function (target, intersects) {
      target.set({
        left: (intersects[0].uv.x * 4096) - (target.width/2),
        top: (intersects[0].uv.y * 4096) - (target.height/2)
      });

      target.setCoords();
      fabricCanvas.requestRenderAll();
    }

    // the code below runs only once
    if (props.canvas) return

    if(window.canvasLoaded) return


    // create instance
    const fabricCanvas = new fabric.Canvas('c').setDimensions({ width: 4096, height: 4096 });
    fabricCanvas.preserveObjectStacking = true;
    fabricCanvas.originalW = fabricCanvas.width;
    fabricCanvas.originalH = fabricCanvas.height;
    //fabricCanvas.custom_attribute_array = ['default', 'id', 'scaleX', 'scaleY'];
    window.fabricCanvas = fabricCanvas;


    // set up selection style
    fabric.Object.prototype.transparentCorners = false;
    fabric.Object.prototype.cornerStyle = 'circle';
    fabric.Object.prototype.borderColor = '#C00000';
    fabric.Object.prototype.cornerColor = '#C00000';
    fabric.Object.prototype.cornerStrokeColor = '#FFF';
    fabric.Object.prototype.padding = 0;
    //EDITED
    fabric.Object.prototype.borderScaleFactor = 10;
    fabric.Object.prototype.cornerSize = 50;
    fabric.Object.prototype.objectCaching = true;

    // retrieve active selection to react state
    fabricCanvas.on('selection:created', (e) => props.setActiveSelection(e.target));
    fabricCanvas.on('selection:updated', (e) => props.setActiveSelection(e.target));
    fabricCanvas.on('selection:cleared', (e) => props.setActiveSelection(null));



    // snap to an angle on rotate if shift key is down
    fabricCanvas.on('object:rotating', (e) => {
      if (e.e.shiftKey) {
        e.target.snapAngle = 15;
      } else {
        e.target.snapAngle = false;
      }
    })



    // display selected object info
    fabricCanvas.on('object:scaling', (e) => {
      const obj = e.target
      props.setSelectionInfo(`${__('width')}: ${ Math.round(obj.getScaledWidth()) }px |
       ${__('height')}: ${ Math.round(obj.getScaledHeight()) }px`)
    })
    fabricCanvas.on('object:scaled', (e) => props.setSelectionInfo(null))
    fabricCanvas.on('object:rotating', (e) => props.setSelectionInfo(__('Tip: hold Shift key for 15° angle jumps!')))
    fabricCanvas.on('object:rotated ', (e) => props.setSelectionInfo(null))

    // keep objects within frame
    fabricCanvas.on('object:moving', (e) => {
      const obj = e.target;

      //
      window['updateCanvas']();

      // if object is too big ignore
      if (obj.currentHeight > obj.canvas.height || obj.currentWidth > obj.canvas.width) {
        return;
      }

      // set offset for moving out the canvas (20px of object persists in canvas)
      const offsetWidth = obj.getBoundingRect().width - 20;
      const offsetHeight = obj.getBoundingRect().height - 20;

      obj.setCoords();

      // top-left corner
      /*if (
        obj.getBoundingRect().top < -offsetHeight
        || obj.getBoundingRect().left < -offsetWidth
      ) {
        obj.top = Math.max(obj.top, obj.top - (obj.getBoundingRect().top + offsetHeight));
        obj.left = Math.max(obj.left, obj.left - (obj.getBoundingRect().left + offsetWidth));
      }

      // bot-right corner
      if (
        obj.getBoundingRect().top + obj.getBoundingRect().height > obj.canvas.height + offsetHeight
        || obj.getBoundingRect().left + obj.getBoundingRect().width > obj.canvas.width + offsetWidth
      ) {
        obj.top = Math.min(
          obj.top,
          obj.canvas.height - obj.getBoundingRect().height
          + obj.top - obj.getBoundingRect().top + offsetHeight,
        );
        obj.left = Math.min(
          obj.left,
          obj.canvas.width - obj.getBoundingRect().width
          + obj.left - obj.getBoundingRect().left + offsetWidth,
        );
      }*/

      var border = false;

      if(obj.left < (obj.width * obj.scaleX) /2){
        obj.left = (obj.width * obj.scaleX) /2;
        $(".canvas-holder").css("border", "10px solid #ff000085");

        border = true;
      }

      if(obj.left > 4096 - ((obj.width * obj.scaleX) /2)){
        obj.left = 4096 - ((obj.width * obj.scaleX) /2);
        $(".canvas-holder").css("border", "10px solid #ff000085");

        border = true;
      }

      if(obj.top < (obj.height * obj.scaleY) /2){
        obj.top = (obj.height * obj.scaleY) /2;
        $(".canvas-holder").css("border", "10px solid #ff000085");

        border = true;
      }

      if(obj.top > 4096 - ((obj.height * obj.scaleY) /2)){
        obj.top = 4096 - ((obj.height * obj.scaleY) /2);
        $(".canvas-holder").css("border", "10px solid #ff000085");

        border = true;
      }


      if(!border)
        $(".canvas-holder").css("border", "0px solid #ff000085");
    })



    // move objects with arrow keys
    document.addEventListener('keydown', (e) => {
      const key = e.which || e.keyCode;
      let activeObject;

      if (document.querySelectorAll('textarea:focus, input:focus').length > 0) return;

      if (key === 37 || key === 38 || key === 39 || key === 40) {
        e.preventDefault();
        activeObject = fabricCanvas.getActiveObject();
        if (!activeObject) {
          return;
        }
      }

      if (key === 37) {
        activeObject.left -= 1;
      } else if (key === 39) {
        activeObject.left += 1;
      } else if (key === 38) {
        activeObject.top -= 1;
      } else if (key === 40) {
        activeObject.top += 1;
      }

      if (key === 37 || key === 38 || key === 39 || key === 40) {
        activeObject.setCoords();
        fabricCanvas.renderAll();
        fabricCanvas.trigger('object:modified');
      }
    })



    // deselect active object on outside click
    const deselectActiveObject = (e) => {
      if (e.target.id === 'app') {
        fabricCanvas.discardActiveObject().requestRenderAll();
      }
    }

    document.addEventListener('mousedown', deselectActiveObject)
    document.addEventListener('touchstart', deselectActiveObject)



    // copy/paste
    copyPaste(fabricCanvas, fabric)


    // delete object on del key
    document.addEventListener('keydown', (e) => {
      const key = e.which || e.keyCode;
      if (
        key === 46 &&
        document.querySelectorAll('textarea:focus, input:focus').length === 0
      ) {

        fabricCanvas.getActiveObjects().forEach(obj => {
          fabricCanvas.remove(obj);
        });

        fabricCanvas.discardActiveObject().requestRenderAll();
        fabricCanvas.trigger('object:modified')
      }
    })



    // line drawing
    lineDrawing(fabricCanvas, fabric)


    // path drawing
    pathDrawing(fabricCanvas, fabric)


    // textbox drawing
    textBoxDrawing(fabricCanvas, fabric)


    // add demo objects
    demoContent(fabricCanvas, fabric)


    // save history's first state on startup
    /*setTimeout(() => {
      let states = []
      states.push(fabricCanvas.toJSON())
      props.setHistory({
        index: 0,
        states: states
      })
    }, 800)*/

    window['saveHistory'] = function () {
      //setTimeout(() => {
        let states = []
        states.push(fabricCanvas.toJSON(['lockMovementX', 'lockMovementY', 'lockRotation', 'lockScalingX', 'lockScalingY', 
                                        'hasControls', 'default', 'id', 'scaleX', 'scaleY']))
        props.setHistory({
          index: 0,
          states: states
        })
      //}, 800)
    }



    // add to state
    if(!window.canvasLoaded){
      window.canvasLoaded = true;
      props.setCanvas(fabricCanvas);
    }

    fabric.TextCurved = fabric.util.createClass(fabric.Object, {
      type: 'text-curved',
      diameter: 500,
      kerning: 0,
      text: '',
      flipped: false,
      fill: '#000',
      fontFamily: 'Times New Roman',
      fontSize: 24, // in px
      fontWeight: 'normal',
      fontStyle: '', // "normal", "italic" or "oblique".
      cacheProperties: fabric.Object.prototype.cacheProperties.concat('diameter', 'kerning', 'flipped', 'fill', 'fontFamily', 'fontSize', 'fontWeight', 'fontStyle', 'strokeStyle', 'strokeWidth'),
      strokeStyle: '',
      strokeWidth: 0,
    
      initialize: function (text, options) {
        options || (options = {});
        this.text = text;
    
        this.callSuper('initialize', options);
        this.set('lockUniScaling', true);
    
        // Draw curved text here initially too, while we need to know the width and height.
        var canvas = this.getCircularText();
        this._trimCanvas(canvas);
        this.set('width', canvas.width);
        this.set('height', canvas.height);
      },
    
      _getFontDeclaration: function () {
        return [
          // node-canvas needs "weight style", while browsers need "style weight"
          (fabric.isLikelyNode ? this.fontWeight : this.fontStyle),
          (fabric.isLikelyNode ? this.fontStyle : this.fontWeight),
          this.fontSize + 'px',
          (fabric.isLikelyNode ? ('"' + this.fontFamily + '"') : this.fontFamily)
        ].join(' ');
      },
    
      _trimCanvas: function (canvas) {
        var ctx = canvas.getContext('2d'),
          w = canvas.width,
          h = canvas.height,
          pix = {
            x: [],
            y: []
          },
          n,
          imageData = ctx.getImageData(0, 0, w, h),
          fn = function (a, b) {
            return a - b
          };
    
        for (var y = 0; y < h; y++) {
          for (var x = 0; x < w; x++) {
            if (imageData.data[((y * w + x) * 4) + 3] > 0) {
              pix.x.push(x);
              pix.y.push(y);
            }
          }
        }
        pix.x.sort(fn);
        pix.y.sort(fn);
        n = pix.x.length - 1;
    
        w = pix.x[n] - pix.x[0];
        h = pix.y[n] - pix.y[0];
        var cut = ctx.getImageData(pix.x[0], pix.y[0], w, h);
    
        canvas.width = w;
        canvas.height = h;
        ctx.putImageData(cut, 0, 0);
      },
    
      // Source: http://jsfiddle.net/rbdszxjv/
      getCircularText: function () {
        var text = this.text,
          diameter = this.diameter,
          flipped = this.flipped,
          kerning = this.kerning,
          fill = this.fill,
          inwardFacing = true,
          startAngle = 0,
          canvas = fabric.util.createCanvasElement(),
          ctx = canvas.getContext('2d'),
          cw, // character-width
          x, // iterator
          clockwise = -1; // draw clockwise for aligned right. Else Anticlockwise
    
        if (flipped) {
          startAngle = 180;
          inwardFacing = false;
        }
    
        startAngle *= Math.PI / 180; // convert to radians
    
        // Calc heigt of text in selected font:
        var d = document.createElement('div');
        d.style.fontFamily = this.fontFamily;
        d.style.whiteSpace = 'nowrap';
        d.style.fontSize = this.fontSize + 'px';
        d.style.fontWeight = this.fontWeight;
        d.style.fontStyle = this.fontStyle;
        d.textContent = text;
        document.body.appendChild(d);
        var textHeight = d.offsetHeight * 4;
        document.body.removeChild(d);
    
        canvas.width = canvas.height = diameter;
        ctx.font = this._getFontDeclaration();
    
        // Reverse letters for center inward.
        if (inwardFacing) {
          text = text.split('').reverse().join('')
        };
    
        // Setup letters and positioning
        ctx.translate(diameter / 2, diameter / 2); // Move to center
        startAngle += (Math.PI * !inwardFacing); // Rotate 180 if outward
        ctx.textBaseline = 'middle'; // Ensure we draw in exact center
        ctx.textAlign = 'center'; // Ensure we draw in exact center
    
        // rotate 50% of total angle for center alignment
        for (x = 0; x < text.length; x++) {
          cw = ctx.measureText(text[x]).width;
          startAngle += ((cw + (x == text.length - 1 ? 0 : kerning)) / (diameter / 2 - textHeight)) / 2 * -clockwise;
        }
    
        // Phew... now rotate into final start position
        ctx.rotate(startAngle);
    
        // Now for the fun bit: draw, rotate, and repeat
        for (x = 0; x < text.length; x++) {
          cw = ctx.measureText(text[x]).width; // half letter
          // rotate half letter
          ctx.rotate((cw / 2) / (diameter / 2 - textHeight) * clockwise);
          // draw the character at "top" or "bottom"
          // depending on inward or outward facing
    
          // Stroke
          if (this.strokeStyle && this.strokeWidth) {
            ctx.strokeStyle = this.strokeStyle;
            ctx.lineWidth = this.strokeWidth;
            ctx.miterLimit = 2;
            ctx.strokeText(text[x], 0, (inwardFacing ? 1 : -1) * (0 - diameter / 2 + textHeight));
          }
    
          // Actual text
          ctx.fillStyle = fill;
          ctx.fillText(text[x], 0, (inwardFacing ? 1 : -1) * (0 - diameter / 2 + textHeight));
    
          ctx.rotate((cw / 2 + kerning) / (diameter / 2 - textHeight) * clockwise); // rotate half letter
        }
        return canvas;
      },
    
      _set: function (key, value) {
        switch (key) {
          case 'scaleX':
            this.fontSize *= value;
            this.diameter *= value;
            this.width *= value;
            this.scaleX = 1;
            if (this.width < 1) {
              this.width = 1;
            }
            break;
    
          case 'scaleY':
            this.height *= value;
            this.scaleY = 1;
            if (this.height < 1) {
              this.height = 1;
            }
            break;
    
          default:
            this.callSuper('_set', key, value);
            break;
        }
      },
    
      _render: function (ctx) {
        var canvas = this.getCircularText();
        this._trimCanvas(canvas);
    
        this.set('width', canvas.width);
        this.set('height', canvas.height);
    
        ctx.drawImage(canvas, -this.width / 2, -this.height / 2, this.width, this.height);
    
        this.setCoords();
      },
    
      toObject: function (propertiesToInclude) {
        return this.callSuper('toObject', ['text', 'diameter', 'kerning', 'flipped', 'fill', 'fontFamily', 'fontSize', 'fontWeight', 'fontStyle', 'strokeStyle', 'strokeWidth', 'styles'].concat(propertiesToInclude));
      }
    });
    
    fabric.TextCurved.fromObject = function (object, callback, forceAsync) {
      return fabric.Object._fromObject('TextCurved', object, callback, forceAsync, 'text-curved');
    };
    
  }, [props])

  return (
    <div className="canvas-holder">
      <canvas id="c"></canvas>
      { props.canvas &&
        <div className="canvas-info">{props.selectionInfo}</div>
      }
    </div>
  )

}

export default FabricCanvas;

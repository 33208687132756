import React, { useState, useEffect } from 'react';
import './Download.scss';
import __ from './../utils/translation';
import $ from "jquery";
import { downloadImage, downloadSVG } from '../utils/downloadImage';
import { applyZoom, zoomWithKeys, zoomWithMouse, zoomOptions } from '../utils/zoom';
import { ReactComponent as IconDownload2 } from '../icons/file_download.svg';

const Download = ({ canvas }) => {

    const [downloadMenuVisible, setDownloadMenuVisible] = useState(false)
    const [zoom, setZoom] = useState(1)


    $("#download-svg").on("click", function () {
        setZoom(1);
        applyZoom(canvas, 1);
        setDownloadMenuVisible(false);
        downloadSVG(canvas.toSVG(['default', 'id', 'scaleX', 'scaleY', 'type']));
    });

    $("#download-png").on("click", function () {
        setZoom(1);
        applyZoom(canvas, 1);
        setDownloadMenuVisible(false);
        downloadImage(canvas.toDataURL(), 'png', 'image/png');
    });

    $("#download-jpg").on("click", function () {
        setZoom(1);
        applyZoom(canvas, 1);
        setDownloadMenuVisible(false);
        downloadImage(canvas.toDataURL(), 'jpg', 'image/jpg');
    });

    return (
        <>
            <p className="title">{__('DOWNLOAD')}</p>
            <span>Se você quiser usar o vetor no CorelDRAW, recomendamos que você baixe o vetor como PDF e importe para evitar conflitos</span>
            
            <div className="downloads">
                <div id="download-vistas" data-title="frente-e-costas">
                    <IconDownload2 />
                    <p>Frente e <br></br>Costas</p>
                </div>
                <div id="download-pdf" data-title="resumo-pdf">
                    <IconDownload2 />
                    <p>Resumo <br></br>PDF</p>
                </div>
                <div id="download-svg" data-title="vetor-svg">
                    <IconDownload2 />
                    <p>Vetor <br></br>SVG</p>
                </div>
                <div id="download-svg-pdf" data-title="vetor-pdf">
                    <IconDownload2 />
                    <p>Vetor <br></br>PDF</p>
                </div>
                <div id="download-jpg" data-title="vetor-jpg">
                    <IconDownload2 />
                    <p>Vetor <br></br>JPG</p>
                </div>
                <div className="download-jpg-3d" data-width="4096" data-height="2160" data-title="captura-paisagem">
                    <IconDownload2 />
                    <p>Captura <br></br>Paisagem</p>
                </div>
                <div className="download-jpg-3d" data-width="2160" data-height="4096" data-title="captura-retrato">
                    <IconDownload2 />
                    <p>Captura <br></br>Retrato</p>
                </div>
                <div className="download-jpg-3d" data-width="4096" data-height="4096" data-title="captura-quadrado">
                    <IconDownload2 />
                    <p>Captura <br></br>Quadrado</p>
                </div>
            </div>
        </>
    )
}

export default Download
import React, { useState, useEffect } from 'react';
import './Annotation.scss';
import __ from './../utils/translation';
import $ from "jquery";
import { downloadImage, downloadSVG } from '../utils/downloadImage';
import { applyZoom, zoomWithKeys, zoomWithMouse, zoomOptions } from '../utils/zoom';
import { ReactComponent as IconDownload2 } from '../icons/file_download.svg';

const Annotation = ({ canvas }) => {

    

    return (
        <>
            <p className="title">{__('ANOTAÇÃO')}</p>

            <div id="option-text">
                <span>*Para adicionar uma anotação, clique no botão de adição</span>
                <span>*Ícone da câmera: salve a visualização atual</span>
                <span>*Ícone da lixeira: exclua a anotação atual</span>
                <span>*Ícone de seta: expandir anotação</span>
                <span>*Ícone do lápis: Editar o ponto da anotação</span>
            </div>

            <div id="option-annotation">
                <i className="fas fa-plus-circle" id="add-annotation"></i>
            </div>
            
            <div id="annotation">
                
            </div>
        </>
    )
}

export default Annotation
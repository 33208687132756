import React from 'react';
import './Layers.scss';
import { fabric } from '../fabric/dist/fabric.js';
import __ from './../utils/translation';
import $ from "jquery";

const Layers = ({ canvas }) => {

  return (
    <>
        <p className="title">{__('HIERARQUIA')}</p>
        <div id="panel-graph">
                <button id="update-graph">
                  Atualizar Hierarquia
                </button>
              </div>
              <div className="folder-tree-wrapper">
                <ul className="folder-tree boxDrag" id="boxDrag"></ul>
                <div id="itemClipDrag" className="itemDrag itemClip hide"></div>
              </div>
    </>
  )

}

export default Layers


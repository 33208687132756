import React from 'react';
import './ChangeSport.scss';
import __ from './../utils/translation';
import $ from "jquery";

import { ReactComponent as IconAbrigo } from '../icons/ac_unit_FILL0_wght400_GRAD0_opsz48.svg';
import { ReactComponent as IconBasket } from '../icons/sports_basketball_FILL0_wght400_GRAD0_opsz48.svg';
import { ReactComponent as IconBike } from '../icons/directions_bike_FILL0_wght400_GRAD0_opsz48.svg';
import { ReactComponent as IconFootball } from '../icons/sports_football_FILL0_wght400_GRAD0_opsz48.svg';
import { ReactComponent as IconSoccer } from '../icons/sports_soccer_FILL0_wght400_GRAD0_opsz48.svg';
import { ReactComponent as IconMMA } from '../icons/sports_mma_FILL0_wght400_GRAD0_opsz48.svg';
import { ReactComponent as IconMoto } from '../icons/sports_motorsports_FILL0_wght400_GRAD0_opsz48.svg';
import { ReactComponent as IconSwim } from '../icons/pool_FILL0_wght400_GRAD0_opsz48.svg';
import { ReactComponent as IconFish } from '../icons/phishing_FILL0_wght400_GRAD0_opsz48.svg';
import { ReactComponent as IconPilot } from '../icons/directions_car_FILL0_wght400_GRAD0_opsz48.svg';
import { ReactComponent as IconSurf } from '../icons/surfing_FILL0_wght400_GRAD0_opsz48.svg';
import { ReactComponent as IconTenis } from '../icons/sports_tennis_FILL0_wght400_GRAD0_opsz48.svg';
import { ReactComponent as IconVolei } from '../icons/sports_volleyball_FILL0_wght400_GRAD0_opsz48.svg';
import { ReactComponent as IconGym } from '../icons/fitness_center_FILL0_wght400_GRAD0_opsz48.svg';

import { ReactComponent as IconCircle } from '../icons/circle.svg';
import { ReactComponent as IconSquare } from '../icons/square.svg';
import { ReactComponent as IconMotorCycle } from '../icons/motorcycle_FILL0_wght400_GRAD0_opsz48.svg';
import { ReactComponent as IconVest } from '../icons/checkroom_FILL0_wght400_GRAD0_opsz48.svg';

import { ReactComponent as IconBanner } from '../icons/flag_FILL0_wght400_GRAD0_opsz48.svg';
import { ReactComponent as IconGlouve } from '../icons/back_hand_FILL0_wght400_GRAD0_opsz48.svg';
import { ReactComponent as IconCup } from '../icons/local_cafe_FILL0_wght400_GRAD0_opsz48.svg';
//

const ChangeSport = ({ canvas }) => {

    /*if(window.id_sport){
        setTimeout(function () {
            $(".sport-type").css("text-decoration", "none");
            $("#" + window.id_sport).css("text-decoration", "underline");

            $(".sport").css("display", "none");
            $("." + window.gender_sport).css("display", "flex");
        }, 1);
    }*/

    {/**if(window.id_sport_type){
        setTimeout(function () {
            $(".sport").css("border", "none");
            $(".sport").css("background", "transparent");
            $(".sport").css("color", "#777");
            $("svg").css("filter", "none");

            $("#" + window.id_sport_type).css("border", "2px solid grey");
            $("#" + window.id_sport_type).css("background", "black");
            $("#" + window.id_sport_type).css("color", "white");
            $("#" + window.id_sport_type).children()[0].style.filter = "invert(1)";
        }, 1);        
    } */}

    /*$("body").on('click', '.sport-type', function () {
        $(".sport-type").css("text-decoration", "none");
        $(this).css("text-decoration", "underline");

        $(".sport").css("display", "none");
        $("." + $(this).data("gender")).css("display", "flex");

        window.id_sport = $(this).attr("id");
        window.gender_sport = $(this).data("gender");
    });*/

    return (
        <>
            <p className="title">{__('MUDAR VESTIMENTA')}</p>

            {/**<div className='options options-change'>
                <p id="sport-male" data-gender="male" className="subtitle sport-type">{__('Male')}</p>
                <p id="sport-female" data-gender="female" className="subtitle sport-type">{__('Female')}</p>

                <p id="sport-motos" data-gender="moto" className="subtitle sport-type">{__('Motorcycles')}</p>
                <p id="sport-banner" data-gender="banner" className="subtitle sport-type">{__('Banner')}</p>
                <p id="sport-vest" data-gender="vest" className="subtitle sport-type">{__('Mockups')}</p>
                <p id="sport-outros" data-gender="outros" className="subtitle sport-type">{__('Others')}</p>
            </div> */}

            {/**<div className='options personalize personalize-items'>
                    <div className="input-select">
                        <div className="select" id='select-sport'>
                            <div className="active-option" id="active-option-sport">Manequim</div>
                            <div className="list" id='list-sports'>
                                <div data-type="dummy" className="option option-sport">Kits com Manequim</div>
                                <div data-type="ind" className="option option-sport">Peças individuais</div>
                                <div data-type="cab" className="option option-sport">Cabides</div>
                                <div data-type="vest" className="option option-sport">Mockups</div>
                                <div data-type="moto" className="option option-sport">Moto</div> 
                                <div data-type="banner" className="option option-sport">Banners</div>
                                <div data-type="caps" className='option option-sport'>Bonés</div>
                                <div data-type="outros" className='option option-sport'>Outros</div>
                            </div>
                        </div>
                    </div>
                </div> */}
            
            <div className="sports">
                
                <div className='sports-scroll'>
                {/**MALE */}
                <div data-height="1.8" data-offset="0" className='male sport' data-gender="male" data-sport="abrigo" data-type="zipper_off" data-t="0" data-b="0" data-p="0" data-pu="0" data-pd="0">
                    <p>Sweaters</p>
                </div>
                <div data-height="1.8" data-offset="0" className='male sport' data-gender="male" data-sport="basquete" data-type="gola_o" data-t="1" data-b="1" data-p="0" data-pu="0" data-pd="1">
                    
                    <p>Basquete</p>
                </div>
                <div data-height="1.8" data-offset="0" className='male sport' data-gender="male" data-sport="ciclismo" data-type="curta" data-t="0" data-b="1" data-p="0" data-pu="0" data-pd="1">
                    
                    <p>Ciclismo</p>
                </div>
                <div data-height="1.8" data-offset="0" className='male sport' data-gender="male" data-sport="futebol-americano" data-type="normal"  data-t="0" data-b="1" data-p="0" data-pu="0" data-pd="1">
                    
                    <p>Futebol Americano</p>
                </div>
                <div data-height="1.8" data-offset="0" className='male sport' data-gender="male" data-sport="futebol" data-type="gola_redonda"  data-t="0" data-b="1" data-p="0" data-pu="0" data-pd="1">
                    
                    <p>Futebol</p>
                </div>
                <div data-height="1.8" data-offset="1.2" className='male sport' data-gender="male" data-sport="mma" data-type="normal"  data-t="1" data-b="1" data-p="1" data-pu="0" data-pd="1">
                    <p>MMA</p>
                </div>
                <div data-height="1.8" data-offset="0" className='male sport' data-gender="male" data-sport="motocross" data-type="normal_A"  data-t="0" data-b="0" data-p="0" data-pu="0" data-pd="0">
                    <p>Motocross</p>
                </div>
                <div data-height="1.8" data-offset="0" className='male sport'data-gender="male" data-sport="natacao" data-type="normal"  data-t="1" data-b="1" data-p="1" data-pu="0" data-pd="1">
                    
                    <p>Natação</p>
                </div>
                <div data-height="1.8" data-offset="0" className='male sport' data-gender="male" data-sport="pesca" data-type="capuz-zipper-total"   data-t="0" data-b="0" data-p="0" data-pu="0" data-pd="0">
                    
                    <p>Pescaria</p>
                </div>
                <div data-height="1.8" data-offset="0" className='male sport' data-gender="male" data-sport="piloto" data-type="normal"   data-t="0" data-b="0" data-p="1" data-pu="0" data-pd="0">
                    
                    <p>Piloto</p>
                </div>
                <div data-height="1.8" data-offset="1.8" className='male sport' data-gender="male" data-sport="surf" data-type="bermuda"  data-t="1" data-b="1" data-p="1" data-pu="1" data-pd="1">
                    
                    <p>Surf</p>
                </div>
                <div data-height="1.8" data-offset="0" className='male sport' data-gender="male" data-sport="tenis" data-type="gola_o" data-t="0" data-b="1" data-p="1" data-pu="0" data-pd="1">
                    
                    <p>Tenis</p>
                </div>
                <div data-height="1.8" data-offset="0" className='male sport' data-gender="male" data-sport="volei" data-type="gala-v-manga" data-t="0" data-b="1" data-p="1" data-pu="0" data-pd="1">
                    
                    <p>Volei</p>
                </div>
                <div data-height="1.8" data-offset="0" className='male sport' data-gender="male" data-sport="casual1" data-type="casual1" data-t="0" data-b="0" data-p="0" data-pu="0" data-pd="0">
                    
                    <p>Casual</p>
                </div>
                {/**<div id="male-casual" className='male sport' data-gender="male" data-sport="casual2" data-type="casual">
                    <IconVolei />
                    <p>Casual 2</p>
                </div>
                <div id="male-casual" className='male sport' data-gender="male" data-sport="casual3" data-type="casual">
                    <IconVolei />
                    <p>Casual 3</p>
                </div> */}

                {/**FEMALE */}

                <div data-height="1.8" data-offset="0" className='female sport' data-gender="female" data-sport="basquete" data-type="gola_o"
                 data-t="1" data-b="1" data-p="0" data-pu="0" data-pd="1">
                    
                    <p>Basket</p>
                </div>
                <div data-height="1.8" data-offset="0" className='female sport' data-gender="female" data-sport="ciclismo" data-type="curta"
                data-t="0" data-b="1" data-p="0" data-pu="0" data-pd="1">
                    
                    <p>Ciclismo</p>
                </div>
                <div data-height="1.8" data-offset="0" className='female sport' data-gender="female" data-sport="futebol" data-type="gola_v_curta"
                data-t="0" data-b="1" data-p="0" data-pu="0" data-pd="1">
                    
                    <p>Futebol</p>
                </div>
                <div data-height="1.8" data-offset="1.4" className='female sport' data-gender="female" data-sport="mma" data-type="normal"
                data-t="1" data-b="1" data-p="1" data-pu="1" data-pd="1">
                    
                    <p>MMA</p>
                </div>
                <div data-height="1.8" data-offset="2.1" className='female sport' data-gender="female" data-sport="natacao" data-type="normal"
                data-t="1" data-b="1" data-p="1" data-pu="1" data-pd="1">
                    
                    <p>Natação</p>
                </div>
                <div data-height="1.8" data-offset="2.1" className='female sport' data-gender="female" data-sport="surf" data-type="maio"
                data-t="1" data-b="1" data-p="1" data-pu="1" data-pd="1">
                    
                    <p>Surf</p>
                </div>
                <div data-height="1.8" data-offset="0"className='female sport' data-gender="female" data-sport="tenis" data-type="gola_v"
                data-t="0" data-b="1" data-p="0" data-pu="1" data-pd="1">
                    
                    <p>Tenis</p>
                </div>
                <div data-height="1.8" data-offset="0" className='female sport' data-gender="female" data-sport="volei" data-type="gala-v-manga"
                data-t="0" data-b="1" data-p="0" data-pu="1" data-pd="1">
                    
                    <p>Volei</p>
                </div>
                <div data-height="1.8" data-offset="0" className='female sport' data-gender="female" data-sport="academia" data-type="regata"
                data-t="1" data-b="1" data-p="0" data-pu="0" data-pd="1">
                    
                    <p>Academia</p>
                </div>
                <div data-height="1.8" data-offset="0" className='female sport' data-gender="female" data-sport="casual1" data-type="casual1"
                data-t="0" data-b="0" data-p="0" data-pu="0" data-pd="0">
                    
                    <p>Casual</p>
                </div>
                {/**<div id="female-casual" className='female sport' data-gender="female" data-sport="casual2" data-type="casual">
                    <IconVolei />
                    <p>Casual 2</p>
                </div>
                <div id="female-casual" className='female sport' data-gender="female" data-sport="casual3" data-type="casual">
                    <IconVolei />
                    <p>Casual 3</p>
                </div> */}
                {/**<div id="banner-circle" className='banner sport' data-gender="banner" data-sport="mockup" data-type="circle">
                    <IconCircle />
                    <p>Round</p>
                </div>
                <div id="banner-square" className='banner sport' data-gender="banner" data-sport="mockup" data-type="square">
                    <IconSquare />
                    <p>Square</p>
                </div> */}
                <div data-height="1.8" data-offset="0" className='banner sport' data-gender="banner" data-sport="mockup" data-type="windbanner1">
                    
                    <p>Wind Banner 1</p>
                </div>
                <div data-height="1.8" data-offset="0" className='banner sport' data-gender="banner" data-sport="mockup" data-type="windbanner2">
                    
                    <p>Wind Banner 2</p>
                </div>
                <div data-height="1.8" data-offset="0" className='banner sport' data-gender="banner" data-sport="mockup" data-type="windbanner3">
                    
                    <p>Wind Banner 3</p>
                </div>
                <div data-height="1.8" data-offset="0" className='banner sport' data-gender="banner" data-sport="mockup" data-type="windbanner4">
                    <p>Wind Banner 4</p>
                </div>
                <button id="custom-banner-rec" className='banner'>Retângulo/Quadrado</button>
                <button id="custom-banner-round" className='banner'>Circulo</button>
                {/**-------------------------------------------------------------------- */}
                <div data-height="1.3" data-offset="0" id="" className='moto sport' data-gender="moto" data-sport="mockup" data-type="gasgas">
                    
                    <p>Gasgas</p>
                </div>
                <div data-height="1.3" data-offset="0" id="" className='moto sport' data-gender="moto" data-sport="mockup" data-type="honda">
                    
                    <p>Honda</p>
                </div>
                <div data-height="1.3" data-offset="0" id="" className='moto sport' data-gender="moto" data-sport="mockup" data-type="huskvarna">
                    
                    <p>Huskvarna</p>
                </div>
                <div data-height="1.3" data-offset="0" id="" className='moto sport' data-gender="moto" data-sport="mockup" data-type="kawazaki">
                    
                    <p>Kawazaki</p>
                </div>
                <div data-height="1.3" data-offset="0" id="" className='moto sport' data-gender="moto" data-sport="mockup" data-type="ktm-150">
                    
                    <p>KTM 150 SX 2020</p>
                </div>
                <div data-height="1.3" data-offset="0" id="" className='moto sport' data-gender="moto" data-sport="mockup" data-type="ktm-450">
                    
                    <p>KTM 450 SX-F 2022</p>
                </div>
                <div data-height="1.3" data-offset="0" id="" className='moto sport' data-gender="moto" data-sport="mockup" data-type="ktm-sx">
                    
                    <p>KTM SX65 2016</p>
                </div>
                <div data-height="1.3" data-offset="0" id="" className='moto sport' data-gender="moto" data-sport="mockup" data-type="suzuki">
                    
                    <p>Suzuki</p>
                </div>
                <div data-height="1.3" data-offset="0" id="" className='moto sport' data-gender="moto" data-sport="mockup" data-type="yamaha">
                    
                    <p>Yamaha</p>
                </div>
                <div data-height="0.25" data-offset="0" data-vertical="0.125" id="" className='moto sport' data-gender="moto" data-sport="mockup" data-type="helmet">
                    
                    <p>Capacete</p>
                </div>
                {/**----------------------------------------------------------------------------------- */}
                <div data-height="1" data-offset="1"  data-vertical="0" id="" className='vest sport' data-gender="vest" data-sport="mockup" data-type="aeroready">
                    <p>Aeroready</p>
                </div>
                <div  data-height="0.7" data-offset="0.5"  id="" className='vest sport' data-gender="vest" data-sport="mockup" data-type="black-short">
                    
                    <p>Black Short</p>
                </div>
                <div data-height="1.1" data-offset="0.7" id="" className='vest sport' data-gender="vest" data-sport="mockup" data-type="colete">
                    <p>Vest</p>
                </div>
                <div data-height="0.8" data-offset="1" id="" className='vest sport' data-gender="vest" data-sport="mockup" data-type="cycling-suit-top">
                    
                    <p>Cycling Suit Top</p>
                </div>
                <div data-height="0.8" data-offset="0" id="" className='vest sport' data-gender="vest" data-sport="mockup" data-type="dog-hoodie">
                    
                    <p>Dog Hoodie Jumper</p>
                </div>
                <div data-height="0.9" data-offset="0.9" id="" className='vest sport' data-gender="vest" data-sport="mockup" data-type="football">
                    
                    <p>Football</p>
                </div>
                <div data-height="0.9" data-offset="0.9" id="" className='vest sport' data-gender="vest" data-sport="mockup" data-type="jacket-l">
                    
                    <p>Jacket L</p>
                </div>
                <div data-height="0.8" data-offset="1" id="" className='vest sport' data-gender="vest" data-sport="mockup" data-type="jersey">
                    
                    <p>Jersey</p>
                </div>
                <div data-height="0.7" data-offset="1.05" id="" className='vest sport' data-gender="vest" data-sport="mockup" data-type="male-short-shirt">
                    
                    <p>Male Short Shirt</p>
                </div>
                <div data-height="0.7" data-offset="1.05" id="" className='vest sport' data-gender="vest" data-sport="mockup" data-type="male-tank">
                    
                    <p>Male Tank</p>
                </div>
                <div data-height="1" data-offset="1.05" id="" className='vest sport' data-gender="vest" data-sport="mockup" data-type="m-hoodie">
                    
                    <p>M-Hood</p>
                </div>
                <div data-height="0.8" data-offset="1.05" id="" className='vest sport' data-gender="vest" data-sport="mockup" data-type="mi-team">
                    
                    <p>MiTeam</p>
                </div>
                <div data-height="0.75" data-offset="1" id="" className='vest sport' data-gender="vest" data-sport="mockup" data-type="m-jacket">
                    
                    <p>M-Jacket</p>
                </div>
                <div data-height="1.1" data-offset="0.15" id="" className='vest sport' data-gender="vest" data-sport="mockup" data-type="m-pants">
                    
                    <p>M-Pants</p>
                </div>
                <div data-height="0.95" data-offset="1.05" id="" className='vest sport' data-gender="vest" data-sport="mockup" data-type="dryfit">
                    
                    <p>Dry Fit</p>
                </div>
                <div data-height="0.7" data-offset="1" id="" className='vest sport' data-gender="vest" data-sport="mockup" data-type="soccer-red">
                    
                    <p>Soccer Red</p>
                </div>
                <div data-height="1.6" data-offset="0.1" id="" className='vest sport' data-gender="vest" data-sport="mockup" data-type="track-suit">
                    
                    <p>Track Suit</p>
                </div>
                <div data-height="0.8" data-offset="1.05" id="" className='vest sport' data-gender="vest" data-sport="mockup" data-type="ultra-wave">
                    
                    <p>Ultra Wave</p>
                </div>
                <div data-height="0.95" data-offset="1.05" id="" className='vest sport' data-gender="vest" data-sport="mockup" data-type="v-neck-football">
                    
                    <p>V-Neck Football</p>
                </div>
                <div data-height="0.95" data-offset="1" id="" className='vest sport' data-gender="vest" data-sport="mockup" data-type="warrix-football">
                    
                    <p>Warrix Football</p>
                </div>
                <div data-height="0.75" data-offset="1.05" id="" className='vest sport' data-gender="vest" data-sport="mockup" data-type="basic-thank-top">
                    
                    <p>Basic Thank Top</p>
                </div>
                <div data-height="0.75" data-offset="1.05" id="" className='vest sport' data-gender="vest" data-sport="mockup" data-type="basic-tshirt">
                    
                    <p>Basic T-shirt</p>
                </div>
                <div data-height="0.85" data-offset="1" id="" className='vest sport' data-gender="vest" data-sport="mockup" data-type="bomber-jacket">
                    
                    <p>Bomber Jacket</p>
                </div>
                <div data-height="0.5" data-offset="0.75" id="" className='vest sport' data-gender="vest" data-sport="mockup" data-type="male-sport-short">
                    
                    <p>Male Sport Short</p>
                </div>
                <div data-height="0.8" data-offset="1" id="" className='vest sport' data-gender="vest" data-sport="mockup" data-type="regular-tshirt">
                    
                    <p>Regular T-shirt</p>
                </div>
                <div data-height="1.1" data-offset="0.1" id="" className='vest sport' data-gender="vest" data-sport="mockup" data-type="sports-pants">
                    
                    <p>Sports Pants</p>
                </div>
                <div data-height="0.75" data-offset="0.7" id="" className='vest sport' data-gender="vest" data-sport="mockup" data-type="tennis-woman">
                    
                    <p>Tennis Woman</p>
                </div>

                <div data-height="0.75" data-offset="0.95" id="" className='vest sport' data-gender="vest" data-sport="mockup" data-type="regular_t_shirt_1">
                    
                    <p>Regular t-shirt 1</p>
                </div>

                <div data-height="0.75" data-offset="1.05" id="" className='vest sport' data-gender="vest" data-sport="mockup" data-type="regular_t_shirt_2">
                    
                    <p>Regular t-shirt 2</p>
                </div>

                <div data-height="0.75" data-offset="1" id="" className='vest sport' data-gender="vest" data-sport="mockup" data-type="v_neck_t_shirt">
                    
                    <p>V Neck T-Shirt</p>
                </div>

                <div data-height="0.75" data-offset="1" id="" className='vest sport' data-gender="vest" data-sport="mockup" data-type="male_tank_top">
                    
                    <p>Male Tank Top</p>
                </div>

                <div data-height="0.75" data-offset="1" id="" className='vest sport' data-gender="vest" data-sport="mockup" data-type="allnasser">
                    
                    <p>AllNasser</p>
                </div>
                
                {/*<div data-height="1.6" data-offset="0.1" id="" className='vest sport' data-gender="vest" data-sport="mockup" data-type="sport-wear-1">
                    
                    <p>Sport Wear 1</p>
                </div>
                <div data-height="1.1" data-offset="0.6" id="" className='vest sport' data-gender="vest" data-sport="mockup" data-type="sport-wear-2">
                    
                    <p>Sport Wear 2</p>
                </div>
                <div data-height="1.6" data-offset="0.1" id="" className='vest sport' data-gender="vest" data-sport="mockup" data-type="sport-wear-3">
                    
                    <p>Sport Wear 3</p>
                </div>
                <div data-height="1.7" data-offset="0.1" id="" className='vest sport' data-gender="vest" data-sport="mockup" data-type="sport-wear-4">
                    
                    <p>Sport Wear 4</p>
                </div>
                <div data-height="0.95" data-offset="0.8" id="" className='vest sport' data-gender="vest" data-sport="mockup" data-type="sport-wear-5">
                    
                    <p>Sport Wear 5</p>
            </div>*/}
                {/**-------------------------------------------------------------------------------- */}
                <div data-height="0.75" data-offset="-0.1" id="" className='outros sport' data-gender="others" data-sport="mockup" data-type="caneca">
                    
                    <p>Copo</p>
                </div>
                <div data-height="1.5" data-offset="-0.75" id="" className='outros sport' data-gender="others" data-sport="mockup" data-type="prancha">
                    
                    <p>Prancha de Surf</p>
                </div>
                <div data-height="1.1" data-offset="-0.5" id="" className='outros sport' data-gender="others" data-sport="mockup" data-type="gloves">
                    
                    <p>Luvas</p>
                </div>
                <div data-height="0.75" data-offset="-0.1" id="" className='outros sport remove' data-gender="others" data-sport="mockup" data-type="baseball-cap">
                    
                    <p>Chapéu de Baseball</p>
                </div>
                <div data-height="0.75" data-offset="-0.1" id="" className='outros sport remove' data-gender="others" data-sport="mockup" data-type="bucket-hat">
                    
                    <p>Bucket Hat</p>
                </div>

                {/**MAIN */}
                <div data-height="1.8" data-offset="0" className='dummy sport remove' data-gender="dummy" data-sport="mockup" data-type="futebol_1" data-t="0" data-b="0" data-p="0" data-pu="0" data-pd="0">
                    <p>Futebol</p>
                </div>
                <div data-height="1.8" data-offset="0" className='dummy sport remove' data-gender="dummy" data-sport="mockup" data-type="mma" data-t="0" data-b="0" data-p="0" data-pu="0" data-pd="0">
                    <p>Short MMA</p>
                </div>
                <div data-height="1.8" data-offset="0" className='dummy sport remove' data-gender="dummy" data-sport="mockup" data-type="short_soccer" data-t="0" data-b="0" data-p="0" data-pu="0" data-pd="0">
                    <p>Short Futebol</p>
                </div>

                <div data-height="1.8" data-offset="0" className='vest sport' data-gender="dummy" data-sport="mockup" data-type="babylook_adidas" data-t="0" data-b="0" data-p="0" data-pu="0" data-pd="0">
                    <p>Baby Look</p>
                </div>
                <div data-height="1.8" data-offset="0" className='vest sport' data-gender="dummy" data-sport="mockup" data-type="basquete_completo" data-t="0" data-b="0" data-p="0" data-pu="0" data-pd="0">
                    <p>Basquete Completo</p>
                </div>
                <div data-height="1.8" data-offset="0" className='vest sport' data-gender="dummy" data-sport="mockup" data-type="camisa_pesca" data-t="0" data-b="0" data-p="0" data-pu="0" data-pd="0">
                    <p>Camisa Pesca</p>
                </div>
                <div data-height="1.8" data-offset="0" className='dummy sport remove' data-gender="dummy" data-sport="mockup" data-type="evo_22_futebol" data-t="0" data-b="0" data-p="0" data-pu="0" data-pd="0">
                    <p>EVO 22 Futebol</p>
                </div>
                <div data-height="1.8" data-offset="0" className='dummy sport remove' data-gender="dummy" data-sport="mockup" data-type="tam_g_futebol" data-t="0" data-b="0" data-p="0" data-pu="0" data-pd="0">
                    <p>Futebol G</p>
                </div>
                <div data-height="1.8" data-offset="0" className='dummy sport remove' data-gender="dummy" data-sport="mockup" data-type="modelo_avante_bronze1" data-t="0" data-b="0" data-p="0" data-pu="0" data-pd="0">
                    <p>Conjunto Avante</p>
                </div>
                <div data-height="1.8" data-offset="-0.8" className='dummy sport remove' data-gender="dummy" data-sport="mockup" data-type="camisa_no_cabide2" data-t="0" data-b="0" data-p="0" data-pu="0" data-pd="0">
                    <p>Camisa no Cabide</p>
                </div>
                <div data-height="1.8" data-offset="0" className='dummy sport remove' data-gender="dummy" data-sport="mockup" data-type="conjunto_max" data-t="0" data-b="0" data-p="0" data-pu="0" data-pd="0">
                    <p>Conjunto Max</p>
                </div>
                <div data-height="1.8" data-offset="1" className='dummy sport remove' data-gender="dummy" data-sport="mockup" data-type="volei_de_areia" data-t="0" data-b="0" data-p="0" data-pu="0" data-pd="0">
                    <p>Volei de Areia</p>
                </div>
                <div data-height="1.8" data-offset="0" className='dummy sport remove' data-gender="dummy" data-sport="mockup" data-type="uniforme_basico" data-t="0" data-b="0" data-p="0" data-pu="0" data-pd="0">
                    <p>Uniforme Básico</p>
                </div>
                <div data-height="1.8" data-offset="-1" className='dummy sport remove' data-gender="dummy" data-sport="mockup" data-type="uniforme" data-t="0" data-b="0" data-p="0" data-pu="0" data-pd="0">
                    <p>Uniforme</p>
                </div>
                <div data-height="1.8" data-offset="0" className='dummy sport remove' data-gender="dummy" data-sport="mockup" data-type="goleiro" data-t="0" data-b="0" data-p="0" data-pu="0" data-pd="0">
                    <p>Goleiro</p>
                </div>

                <div data-height="1.8" data-offset="-1" className='dummy sport remove' data-gender="dummy" data-sport="mockup" data-type="camisa" data-t="0" data-b="0" data-p="0" data-pu="0" data-pd="0">
                    <p>Camisa</p>
                </div>

                <div data-height="1.8" data-offset="-1" className='dummy sport remove' data-gender="dummy" data-sport="mockup" data-type="camisa_gola" data-t="0" data-b="0" data-p="0" data-pu="0" data-pd="0">
                    <p>Camisa Gola</p>
                </div>
                <div data-height="1.8" data-offset="-0.8" className='dummy sport remove' data-gender="dummy" data-sport="mockup" data-type="camisa_gola_cabide" data-t="0" data-b="0" data-p="0" data-pu="0" data-pd="0">
                    <p>Camisa Gola Cabide</p>
                </div>


                <div data-height="1.8" data-offset="0" className='dummy sport remove' data-gender="dummy" data-sport="mockup" data-type="camisa_esportiva" data-t="0" data-b="0" data-p="0" data-pu="0" data-pd="0">
                    <p>Camisa Esportiva</p>
                </div>
                <div data-height="1.8" data-offset="0" className='dummy sport remove' data-gender="dummy" data-sport="mockup" data-type="camisa_esportiva2" data-t="0" data-b="0" data-p="0" data-pu="0" data-pd="0">
                    <p>Camisa Esportiva 2</p>
                </div>

                <div data-height="10" data-offset="-5" className='ind sport' data-gender="dummy" data-sport="mockup" data-type="gola_padre" data-t="0" data-b="0" data-p="0" data-pu="0" data-pd="0">
                    <p>Gola Padre</p>
                </div>
                <div data-height="10" data-offset="-4.8" className='ind sport' data-gender="dummy" data-sport="mockup" data-type="gola_polo" data-t="0" data-b="0" data-p="0" data-pu="0" data-pd="0">
                    <p>Gola Polo</p>
                </div>

                <div data-height="10" data-offset="-4.95" className='ind sport remove' data-gender="dummy" data-sport="mockup" data-type="gola_v" data-t="0" data-b="0" data-p="0" data-pu="0" data-pd="0">
                    <p>Gola V</p>
                </div>

                <div data-height="4" data-offset="-1.1" className='dummy sport remove' data-gender="dummy" data-sport="mockup" data-type="evo_kit" data-t="0" data-b="0" data-p="0" data-pu="0" data-pd="0">
                    <p>Kit Evo</p>
                </div>


                

                <div data-height="4" data-offset="-1.1" className='ind sport' data-gender="dummy" data-sport="mockup" data-type="agasalho_completo" data-t="0" data-b="0" data-p="0" data-pu="0" data-pd="0">
                    <p>Agasalho Completo</p>
                </div>
                <div data-height="4" data-offset="-0.85" className='ind sport' data-gender="dummy" data-sport="mockup" data-type="blusao" data-t="0" data-b="0" data-p="0" data-pu="0" data-pd="0">
                    <p>Blusão com Touca</p>
                </div>
                <div data-height="2" data-offset="2" className='ind sport' data-gender="dummy" data-sport="mockup" data-type="camisa_basica_gola_redonda" data-t="0" data-b="0" data-p="0" data-pu="0" data-pd="0">
                    <p>Camisa Básica Gola Redonda</p>
                </div>
                <div data-height="4" data-offset="-1.3" className='dummy sport remove' data-gender="dummy" data-sport="mockup" data-type="kit_basquete" data-t="0" data-b="0" data-p="0" data-pu="0" data-pd="0">
                    <p>Kit Basquete</p>
                </div>
                <div data-height="4" data-offset="-1.15" className='dummy sport remove' data-gender="dummy" data-sport="mockup" data-type="kit_max" data-t="0" data-b="0" data-p="0" data-pu="0" data-pd="0">
                    <p>Kit Max</p>
                </div>

                <div data-height="4" data-offset="-0.8" className='ind sport' data-gender="dummy" data-sport="mockup" data-type="raglan" data-t="0" data-b="0" data-p="0" data-pu="0" data-pd="0">
                    <p>Raglan</p>
                </div>
                <div data-height="4" data-offset="-0.8" className='ind sport' data-gender="dummy" data-sport="mockup" data-type="camisa_basica_gola_v" data-t="0" data-b="0" data-p="0" data-pu="0" data-pd="0">
                    <p>Camisa Básica Gola V</p>
                </div>
                <div data-height="2" data-offset="2.1" className='ind sport' data-gender="dummy" data-sport="mockup" data-type="camisa_basica_manga_longa" data-t="0" data-b="0" data-p="0" data-pu="0" data-pd="0">
                    <p>Camisa Básica Manga Longa</p>
                </div>

                <div data-height="4" data-offset="-0.95" className='ind sport' data-gender="dummy" data-sport="mockup" data-type="ciclismo_ziper_longo" data-t="0" data-b="0" data-p="0" data-pu="0" data-pd="0">
                    <p>Ciclismo Ziper Longo</p>
                </div>
                <div data-height="4" data-offset="-0.95" className='ind sport' data-gender="dummy" data-sport="mockup" data-type="ciclismo_manga_longa" data-t="0" data-b="0" data-p="0" data-pu="0" data-pd="0">
                    <p>Ciclismo Manga Longa</p>
                </div>
                <div data-height="4" data-offset="-0.95" className='ind sport' data-gender="dummy" data-sport="mockup" data-type="ciclismo_ziper_curto" data-t="0" data-b="0" data-p="0" data-pu="0" data-pd="0">
                    <p>Ciclismo Ziper Curto</p>
                </div>

                
                <div data-height="4" data-offset="-1.15" className='dummy sport remove' data-gender="dummy" data-sport="mockup" data-type="kit_gola_v" data-t="0" data-b="0" data-p="0" data-pu="0" data-pd="0">
                    <p>Kit Gola V</p>
                </div>
                <div data-height="4" data-offset="-1.15" className='dummy sport remove' data-gender="dummy" data-sport="mockup" data-type="kit_gola_redonda" data-t="0" data-b="0" data-p="0" data-pu="0" data-pd="0">
                    <p>Kit Gola Redonda</p>
                </div>


                <div data-height="1.5" data-offset="1.7" className='cab sport' data-gender="dummy" data-sport="mockup" data-type="camisa_basica_gola_v_cabide" data-t="0" data-b="0" data-p="0" data-pu="0" data-pd="0">
                    <p>Camisa Básica Gola V Cabide</p>
                </div>

                <div data-height="1.5" data-offset="1.7" className='cab sport' data-gender="dummy" data-sport="mockup" data-type="gola_polo_cabide" data-t="0" data-b="0" data-p="0" data-pu="0" data-pd="0">
                    <p>Gola Polo Cabide</p>
                </div>

                <div data-height="1.5" data-offset="1.9" className='cab sport' data-gender="dummy" data-sport="mockup" data-type="gola_padre_cabide" data-t="0" data-b="0" data-p="0" data-pu="0" data-pd="0">
                    <p>Gola Padre Cabide</p>
                </div>
                
                <div data-height="1.6" data-offset="1.9" className='cab sport' data-gender="dummy" data-sport="mockup" data-type="raglan_cabide" data-t="0" data-b="0" data-p="0" data-pu="0" data-pd="0">
                    <p>Raglan Cabide</p>
                </div>

                <div data-height="1.6" data-offset="1.9" className='cab sport' data-gender="dummy" data-sport="mockup" data-type="gola_redonda_cabide" data-t="0" data-b="0" data-p="0" data-pu="0" data-pd="0">
                    <p>Gola Redonda Cabide</p>
                </div>

                <div data-height="0.75" data-offset="-0.3" id="" className='caps sport' data-gender="caps" data-sport="mockup" data-type="cap1">
                    <p>Boné 1</p>
                </div>
                <div data-height="0.75" data-offset="-0.3" id="" className='caps sport' data-gender="caps" data-sport="mockup" data-type="cap2">
                    <p>Boné 2</p>
                </div>
                <div data-height="0.75" data-offset="-0.3" id="" className='caps sport' data-gender="caps" data-sport="mockup" data-type="cap3">
                    <p>Boné 3</p>
                </div>
                <div data-height="0.75" data-offset="-0.3" id="" className='caps sport' data-gender="caps" data-sport="mockup" data-type="cap4">
                    <p>Boné 4</p>
                </div>
                <div data-height="0.75" data-offset="-0.3" id="" className='caps sport' data-gender="caps" data-sport="mockup" data-type="cap5">
                    <p>Boné 5</p>
                </div>
                <div data-height="0.75" data-offset="-0.3" id="" className='caps sport' data-gender="caps" data-sport="mockup" data-type="cap6">
                    <p>Boné 6</p>
                </div>
                <div data-height="0.75" data-offset="-0.3" id="" className='caps sport' data-gender="caps" data-sport="mockup" data-type="cap7">
                    <p>Boné 7</p>
                </div>
                <div data-height="0.75" data-offset="-0.3" id="" className='caps sport' data-gender="caps" data-sport="mockup" data-type="cap8">
                    <p>Boné 8</p>
                </div>

                {/** */}

                <div data-height="4" data-offset="-1.15" className='dummy sport' data-gender="dummy" data-sport="mockup" data-type="kit_alpha" data-t="0" data-b="0" data-p="0" data-pu="0" data-pd="0">
                    <p>Kit Alpha</p>
                </div>

                <div data-height="4" data-offset="-1.15" className='dummy sport' data-gender="dummy" data-sport="mockup" data-type="kit_eclipse" data-t="0" data-b="0" data-p="0" data-pu="0" data-pd="0">
                    <p>Kit Eclipse</p>
                </div>

                <div data-height="4" data-offset="-1.15" className='dummy sport' data-gender="dummy" data-sport="mockup" data-type="kit_evolution" data-t="0" data-b="0" data-p="0" data-pu="0" data-pd="0">
                    <p>Kit Evolution</p>
                </div>

                <div data-height="4" data-offset="-1.15" className='dummy sport' data-gender="dummy" data-sport="mockup" data-type="kit_prime_fit" data-t="0" data-b="0" data-p="0" data-pu="0" data-pd="0">
                    <p>Kit Prime Fit</p>
                </div>

                <div data-height="4" data-offset="-1.15" className='dummy sport' data-gender="dummy" data-sport="mockup" data-type="kit_squadra" data-t="0" data-b="0" data-p="0" data-pu="0" data-pd="0">
                    <p>Kit Squadra</p>
                </div>

                </div>
            </div>
        </>
    )
}

export default ChangeSport
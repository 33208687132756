import React, { useState, useEffect } from 'react';
import './BackgroundScene.scss';
import { fabric } from '../fabric/dist/fabric.js';
import { gradToStateObj, stateObjToGrad } from './../utils/gradientTools';
import { Tabs, Tab } from './Tabs';
import { ChromePicker } from 'react-color';
import GradientPicker from './GradientPicker';
import __ from './../utils/translation';
import $ from "jquery";
import ColorPicker from 'react-best-gradient-color-picker';

const BackgroundScene = ({}) => {

    /*const [canvasSettings, setCanvasSettings] = useState({
        width: 640,
        height: 480,
        keepAspectRatio: false,
        color: 'rgba(255, 255, 255, 0)',
        gradient: {
          type: 'linear',
          angle: 90,
          colorStops: [
            { offset: 0, color: 'rgba(0, 0, 0, 1)', id: 0 },
            { offset: 1, color: 'rgba(255, 0, 0, 1)', id: 1 }
          ]
        }
    })*/

    /*const [canvasSettings, setCanvasSettings] = useState({
        color: 'rgba(255, 255, 255, 0)',
    })*/
    const [color, setColor] = useState('rgba(255,255,255,1)');

    /*const handleColorChange = (color) => {
        setCanvasSettings({...canvasSettings, color: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`})
        window['updateColor'](color);
    }

    const handleGradientChange = (gradient) => {
        setCanvasSettings({...canvasSettings, gradient: gradient })
        //let bgGradient = stateObjToGrad(gradient, canvas.width, canvas.height, fabric)
        window['updateGradientColor'](gradient);
    }*/

    const handleColorChange = (color) => {
        setColor(color);
        window['updateColor'](color);
    }

    $(".background-type").bind("click", function () {
        $(".background-type").css("text-decoration", "none");
        $(this).css("text-decoration", "underline");

        $(".thumb-background-hdri").css("display", "none");
        $(".thumb-background-texture").css("display", "none");
        $(".thumb-background-color").css("display", "none");
        $(".thumb-background-" + $(this).data("type")).css("display", "block");

        if($(this).data("type") == "texture"){
            $("#uploadFileParent").css("opacity", "1");
            $("#uploadFileParent").css("pointer-events", "all");

            $("#opacity-panel-background").css("display", "flex");
        }else{
            $("#uploadFileParent").css("opacity", "0.5");
            $("#uploadFileParent").css("pointer-events", "none");

            $("#opacity-panel-background").css("display", "none");
        }
    });

    return (
        <>
            <p className="title">{__('FUNDO')}</p>

            <div className='options'>
                <p id="background-color" data-type="color" className="subtitle background-type">{__('Cor')}</p>
                <p id="background-texture" data-type="texture" className="subtitle background-type">{__('Imagem')}</p>
                <p id="background-hdri" data-type="hdri" className="subtitle background-type">{__('Ambiente')}</p>
            </div>

            <div id="opacity-panel-background">
                <span>Opacidade Fundo:</span>
                <input type="range" min="0" max="1" step="0.01" id="range-background-opacity"></input>

                <div id="uploadFileParent">
                    <input type="file" id="uploadFile" lang='en'></input>
                    <div>
                        <span>Arraste e solte as imagens ou clique para navegar</span>
                    </div>
                </div>
            </div>
            

            <div className='thumb-background-color'>
                {/**<Tabs defaultTab={'color'}>
                    <Tab title={__('Color fill')} name="color"> */}
                    {/**<ChromePicker width="100%" color={canvasSettings.color} onChange={handleColorChange} /> */}
                    {/**</Tab>
                    <Tab title={__('Gradient fill')} name="gradient">
                    <GradientPicker gradient={canvasSettings.gradient} onChange={handleGradientChange}>
                        <ChromePicker />
                    </GradientPicker>
                    </Tab>
                </Tabs> */}
                <ColorPicker value={color} onChange={handleColorChange} />
            </div>
            
            <div className="background" id="menu-level-A">
                <img id="preview-background-texture" className='thumb-background-texture'></img>
            </div>
        </>
    )
}

export default BackgroundScene
/*
 * SVG Editor
 * version: 1.0.1
 *
 */
/* eslint-disable */
import React, { useState, useEffect } from 'react';
import Notification from './components/Notification';
import Header from './components/Header';
import Button from './components/Button';
import FloatingMenu from './components/FloatingMenu';
import Menu from './components/Menu';
import Toolbar from './components/Toolbar';
import FabricCanvas from './components/FabricCanvas';
import ToolPanel from './components/ToolPanel';
import SelectionSettings from './components/SelectionSettings';
import SelectionSettingsIframe from './components/SelectionSettingsIframe';
import CanvasSettings from './components/CanvasSettings';
import DrawSettings from './components/DrawSettings';
import Shapes from './components/Shapes';
import UploadSettings from './components/UploadSettings';
//import LayerParent from './components/LayerParent';

//RAFAEL
import BackgroundScene from './components/BackgroundScene';
import ChangeSport from './components/ChangeSport';
import UploadCustom from './components/UploadCustom';
import Video from './components/Video';
import Animation from './components/Animation';
import Material from './components/Material';
import Download from './components/Download';
import Annotation from './components/Annotation';
import Cloth from './components/Cloth';
import Patterns from './components/Patterns';
import { InputSelect, Option } from './components/InputSelect';

import __ from './utils/translation';
import saveInBrowser from './utils/saveInBrowser';
import { downloadImage, downloadSVG } from './utils/downloadImage';
import { undo, redo } from './utils/undoRedo';
import { editorTips } from './utils/editorTips';
import { handleDrawingModes } from './utils/handleDrawingModes';
import { applyZoom, zoomWithKeys, zoomWithMouse, zoomOptions } from './utils/zoom';

import logo from './images/logo.png';
import { ReactComponent as IconGear } from './icons/gear.svg';
import { ReactComponent as IconUndo } from './icons/undo.svg';
import { ReactComponent as IconRedo } from './icons/redo.svg';
import { ReactComponent as IconTick } from './icons/tick.svg';
import { ReactComponent as IconDownload } from './icons/down.svg';
import { ReactComponent as IconClose } from './icons/close.svg';
import { ReactComponent as IconBrush } from './icons/brush.svg';
import { ReactComponent as IconCursor } from './icons/cursor.svg';
import { ReactComponent as IconLine } from './icons/line.svg';
import { ReactComponent as IconPath } from './icons/path.svg';
import { ReactComponent as IconShape } from './icons/shape.svg';
import { ReactComponent as IconText } from './icons/text.svg';
import { ReactComponent as IconUpload } from './icons/upload.svg';
import { ReactComponent as IconZoom } from './icons/zoom.svg';
import { ReactComponent as IconWallPaper } from './icons/wallpaper.svg';

import { ReactComponent as IconDownload2 } from './icons/file_download.svg';
import { ReactComponent as IconUpload2 } from './icons/file_upload.svg';
import { ReactComponent as IconAR } from './icons/view_in_ar.svg';

import { ReactComponent as IconStudio } from './icons/studio.svg';
import { ReactComponent as IconSports } from './icons/sports.svg';
import { ReactComponent as IconVideo } from './icons/video.svg';
import { ReactComponent as IconCloth } from './icons/cloth.svg';
import { ReactComponent as IconAnimation } from './icons/animation.svg';
import { ReactComponent as IconEdit } from './icons/edit.svg';
import { ReactComponent as IconStyler } from './icons/styler.svg';
import { ReactComponent as IconPlay } from './icons/play.svg';
import { ReactComponent as IconBoy } from './icons/boy.svg';
import { ReactComponent as IconCamera } from './icons/flip_camera_android_FILL0_wght400_GRAD0_opsz48.svg';
import { ReactComponent as IconBody } from './icons/metabolism_FILL0_wght400_GRAD0_opsz48.svg';
import { ReactComponent as IconAutoRenew } from './icons/autorenew.svg';
import { ReactComponent as IconArrowBack } from './icons/arrow_back_FILL0_wght400_GRAD0_opsz24.svg';
import { ReactComponent as IconGraph } from './icons/account_tree_FILL0_wght400_GRAD0_opsz24.svg';
import { ReactComponent as IconLogOut } from './icons/power_settings_new_FILL0_wght400_GRAD0_opsz48.svg';
import { ReactComponent as IconSave } from './icons/save_FILL0_wght400_GRAD0_opsz48.svg';
import { ReactComponent as IconFolder } from './icons/folder_FILL0_wght400_GRAD0_opsz48.svg';
import { ReactComponent as IconColor } from './icons/palette_FILL0_wght400_GRAD0_opsz48.svg';
import { ReactComponent as IconList } from './icons/receipt_long_FILL0_wght400_GRAD0_opsz48.svg';
import { ReactComponent as IconAnnotation } from './icons/edit_note_FILL0_wght400_GRAD0_opsz48.svg';
import { ReactComponent as IconColorPattern } from './icons/format_color_fill_FILL0_wght400_GRAD0_opsz48.svg';
import { ReactComponent as IconPattenrs } from './icons/view_kanban_FILL0_wght400_GRAD0_opsz48.svg';
import { ReactComponent as IconResolution } from './icons/6k_FILL0_wght400_GRAD0_opsz48.svg';
import { useSignOut } from "react-auth-kit";
import {useIsAuthenticated} from 'react-auth-kit';
import $ from "jquery";
import axios, { AxiosError } from "axios";

//
const SimulatorIframe = () => {

  window.canvasLoaded = false;

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  var is_iframe = urlParams.get('i');


    import('./editorIframe.js')
      .then((module) => {
        if(window.name == "not-logged"){
          window.name = "logged";
          window["initSimulatorIframe"]();
        }
    });

  // states
  const [notification, setNotification] = useState({ message: null, type: null, seconds: null })
  const [downloadMenuVisible, setDownloadMenuVisible] = useState(false)
  const [activeTool, setActiveTool] = useState('select')

  const [canvas, setCanvas] = useState()
  const [loadSavedCanvas, setLoadSavedCanvas] = useState(true)
  const [activeSelection, setActiveSelection] = useState(null)
  const [history, setHistory] = useState({ index: null, states: [] })
  const [selectionInfo, setSelectionInfo] = useState(editorTips[Math.floor(Math.random() * editorTips.length)])
  const [zoom, setZoom] = useState(1)

  //--------------------------------------------------------------------


  // on start: check if there is a saved canvas in this browser and ask if we should load it
  useEffect(() => {
    if (canvas && loadSavedCanvas) {
      const savedCanvas = saveInBrowser.load('canvasEditor');
      if (savedCanvas && window.confirm( __('We found a project saved in this browser! Do you want to load it?') )) {
        canvas.loadFromJSON(savedCanvas, canvas.renderAll.bind(canvas));
      }

      setLoadSavedCanvas(false);
    }
  }, [canvas, loadSavedCanvas])


  //--------------------------------------------------------------------


  // on active selection update: change active tool to select
  useEffect(() => {
    if (!activeSelection) return

    //setActiveTool('select')

    // scroll to top in tool panel
    document.querySelector('.toolpanel .holder').scrollTop = 0
  }, [activeSelection])


  //--------------------------------------------------------------------


  // on active tool change: deselect all object, handle drawing modes
  useEffect(() => {
    if (!canvas) return

    if (activeTool !== 'select') canvas.discardActiveObject().requestRenderAll()

    handleDrawingModes(canvas, activeTool, setSelectionInfo)
  }, [canvas, activeTool])


  //--------------------------------------------------------------------


  // save history and unsaved work alert
  const maxHistory = 10
  useEffect(() => {
    if (!canvas) return

    const saveHistory = () => {
      let updatedHistory = [...history.states]

      // if any action happens after undo, clear all (redo) actions after current state
      if (history.index < history.states.length - 1) updatedHistory.splice(history.index + 1)

      // add current state to history
      updatedHistory.push(canvas.toJSON(['lockMovementX', 'lockMovementY', 'lockRotation', 'lockScalingX', 'lockScalingY', 
      'hasControls', 'default', 'id', 'scaleX', 'scaleY']))
      if (updatedHistory.length > maxHistory) updatedHistory.shift()

      setHistory({ index: updatedHistory.length - 1, states: updatedHistory })
    }
    canvas.on('object:modified', saveHistory)
    canvas.on('path:created', saveHistory)


    const unsavedWorkAlert = (e) => {
      if (history.states.length > 1) e.returnValue = __(`Are you sure you want to leave?`)
    }
    window.addEventListener('beforeunload', unsavedWorkAlert)


    // cleanup
    return () => {
      canvas.off('object:modified', saveHistory)
      canvas.off('path:created', saveHistory)

      window.removeEventListener('beforeunload', unsavedWorkAlert)
    }
  }, [canvas, history])


  //--------------------------------------------------------------------


  // keyboard & mouse shortcuts
  useEffect(() => {
    if (!canvas) return

    // select tool (v)
    const keyV = (e) => {
      const key = e.which || e.keyCode;
      if (key === 86 && document.querySelectorAll('textarea:focus, input:focus').length === 0) {
        //setActiveTool('select')
      }
    }
    document.addEventListener('keydown', keyV)


    // undo/redo (ctrl z/y)
    const ctrZY = (e) => {
      const key = e.which || e.keyCode;

      if (key === 90 && e.ctrlKey && document.querySelectorAll('textarea:focus, input:focus').length === 0) {
        undo(canvas, history, setHistory)
      }

      if (key === 89 && e.ctrlKey && document.querySelectorAll('textarea:focus, input:focus').length === 0) {
        redo(canvas, history, setHistory)
      }
    }
    document.addEventListener('keydown', ctrZY)


    // zoom out/in/reset (ctr + -/+/0)
    const keyZoom = (e) => zoomWithKeys(e, canvas, setZoom, applyZoom)
    document.addEventListener('keydown', keyZoom)


    // zoom out/in with mouse
    const mouseZoom = (e) => zoomWithMouse(e, canvas, setZoom, applyZoom)
    document.addEventListener('wheel', mouseZoom, { passive: false })


    // cleanup
    return () => {
      document.removeEventListener('keydown', keyV)
      document.removeEventListener('keydown', ctrZY)
      document.removeEventListener('keydown', keyZoom)
      document.removeEventListener('wheel', mouseZoom)
    }
  }, [canvas, history])

  //--------------------------------------------------------------------

  // render layout
  return (
    <div id="app">

      <div id="msg-block">
        <span>Você não pode mais visualizar e editar esse projeto, aguarde o contato da loja</span>
      </div>

        <div id="panel-crop">
          <div className="image_container">
            <img id="blah" src="#" alt="your image" />
          </div>

          {/**Cropped image to display (only if u want) */}
          <div id="cropped_result"></div>  

          {/**<!-- // Will trigger crop event --> */}
           
          <div id="crop-buttons">
            <button id="crop_button">
              <i className="fas fa-cut"></i>
            </button>
            <button id="close_crop">
              <i className="fas fa-times"></i>
            </button>
          </div>
        </div>

      <div className='panel-default'>
        <div className='panel-default-header'>
          <span id="panel-title">TITLE</span>
          <div>
            <button className='panel panel-color' id="save-color">SAVE COLOR PALLETS</button>
            <button className='panel panel-list' id="download-csv">CSV</button>
            
            <button className='panel panel-projects' id="title-mb">0 MB / 50 MB</button>
            <button className='panel panel-projects' id="btn-save-cache">SAVE</button>
            <button className='panel panel-projects' id="clear-folder">CLEAR ALL</button>
            
            <button id="close-panel">BACK</button>
          </div>
        </div>
        <div className='panel-default-body'>
          <div id="lista-pedidos" className='panel panel-list'>
            <div>
              <span>Name</span>
              <input type="text" id="list-name" />
            </div>
            <div>
              <span>Number</span>
              <input type="number" id="list-number" step="1" />
            </div>
            <div>
              <span>Size</span>
              <input type="number" id="list-size" step="0.1" />
            </div>
            <div>
              <span>Quantity</span>
              <input type="number" id="list-qtd" step="1" />
            </div>
            <div>
              <button id="list-add">ADD</button>
            </div>
          </div>
          <div id="lista-geral" className='panel panel-list'>
            <div>
              <div className="list-block">
                <span>NAME</span>
              </div>
              <div className="list-block">
                <span>NUMBER</span>
              </div>
              <div className="list-block">
                <span>SIZE</span>
              </div>
              <div className="list-block">
                <span>QUANTITY</span>
              </div>
            </div>
          </div>
          <div className='panel panel-projects'>
            <div  id="panel-folder"></div>
          </div>
          <div className='panel panel-color'>
            <div id="my-colors">
              <div id="my-colors-options">
                <div id="options-color">
                  <span id="choose-rgb">RGB</span>
                  <span id="choose-cmk">CMYK</span>
                  <span id="choose-picker">PICKER</span>
                </div>
                <div>
                  <div id="options-rgb">
                    <label>R</label>
                    <input id="my-color-r" min="0" max="255" step="1" type="number" defaultValue="0"></input>
                    <label>G</label>
                    <input id="my-color-g" min="0" max="255" step="1" type="number" defaultValue="0"></input>
                    <label>B</label>
                    <input id="my-color-b" min="0" max="255" step="1" type="number" defaultValue="0"></input>
                  </div>
                  <div id="options-cmk">
                    <label>C</label>
                    <input id="my-color-c" min="0" max="100" step="1" type="number" defaultValue="0"></input>
                    <label>M</label>
                    <input id="my-color-m" min="0" max="100" step="1" type="number" defaultValue="0"></input>
                    <label>Y</label>
                    <input id="my-color-y" min="0" max="100" step="1" type="number" defaultValue="0"></input>
                    <label>K</label>
                    <input id="my-color-k" min="0" max="100" step="1" type="number" defaultValue="0"></input>
                  </div>
                  <div id="options-picker">
                    <input id="my-color-picker" type="color"></input>
                  </div>
                </div>
                <div>
                  <button id="add-color">ADD COLOR</button>
                  <button id="update-color">UPDATE COLOR</button>
                  <button id="remove-color">REMOVE COLOR</button>
                </div>
              </div>
              <section id="list-colors"></section>
            </div>
          </div>
        </div>
      </div>

      {/**<div className='toolbar-buttons'>
          <div className="panel-custom-buttons">

            <button className='svg' id="update-canvas-3d" title='UPDATE 3D'>
              <i className="svg fas fa-sync-alt fa-2x"></i>
            </button>

            <button className='svg' id="expand-canvas" title='HIDE/SHOW CANVAS'>
              <i id="arrow-canvas-right" className="fas fa-arrow-right fa-2x"></i>
              <i id="arrow-canvas-left" className="fas fa-arrow-left fa-2x"></i>
            </button>

            <button className='' id="update-canvas-3d" title='UPDATE 3D'>
              <IconResolution  />
            </button>

            <button className='svg' id="edit-background" title="CHANGE CANVAS">
              <i className="fas fa-retweet fa-2x"></i>
            </button>

            <button className='svg' title='UNDO'>
              <i className="fas fa-history fa-2x"></i>
            </button>
            
            <button className='svg' title='REDO'>
              <i className="fas fa-history fa-2x redo"></i>
            </button>

            <button className='svg' id="canvas-default-size" title='RESET CANVAS ZOOM'>
              <i className="fas fa-search fa-2x"></i>
            </button>
            
            <button className='svg' id="toggle-grid" title='TOGGLE GRID'>
              <i className="fas fa-border-none fa-2x"></i>
            </button>
            
            <button title='FULL SCREEN' id="full_button">
              <i className="fas fa-expand fa-2x"></i>
            </button>

            <button title='TUTORIAL' id="tutorial-start">
              <i className="fas fa-info fa-2x"></i>
            </button>

            <button id="registerColors" title='MY COLOR PALLETS' data-panel="panel-color">
              <i className="fas fa-palette fa-2x"></i>
            </button>
            
            <button className='open-list main' title="MY WHISH LIST" data-panel="panel-list">
              <i className="fas fa-clipboard-list fa-2x"></i>
            </button>
            
            <button className='main' id="open-folder" title='MY PROJECTS SAVED' data-panel="panel-projects">
              <i className="far fa-folder fa-2x" id="open-folder"></i>
            </button>
            
            <button className='main' id="" title='CLIENTS PROJECTS'>
              <i className="fas fa-users fa-2x"></i>
            </button>
            
            <button className='main' id="btn-log-out" title='LOGOUT'>
              <i className="fas fa-power-off fa-2x"></i>
            </button>

            <div className='svg' id="line-svg-button"></div>
          </div>
      </div> */}

      {/**<button className='button-editor' id="canvas-default-size">RESET CANVAS ZOOM</button>
          <button className='button-editor' id="update-canvas-3d">UPDATE 3D</button>
          <button className='button-editor' id="edit-background">EDIT BACKGROUND</button> 
          <button className='button-editor' id="registerColors">REGISTER COLORS</button> 
          <button className='button-editor' id="toggle-grid">TOGLE GRID</button>  */}

      <div id="panel-save">
        <div id="panel-box-save">
          <span id="storage-warning">Storage exceed</span>
          <input type="text" id="input-save-name"></input>
          <div>
            <button id="btn-save-cache-confirm">Salvar</button>
            <button id="btn-close-save">Cancelar</button>
          </div>
        </div>
      </div>

      <div id="loading">
        <div id="loadingNew">
          <p></p>
          <p></p>
          <p></p>
          <p></p>
          <p></p>
          <p></p>
          <p></p>
          <p></p>
          <p></p>
          <p></p>
          <p></p>
          <p></p>
          <p></p>
          <p></p>
          <p></p>
          <p></p>
          <p></p>
          <p></p>
          <p></p>
          <p></p>
          <p></p>
          <p></p>
          <p></p>
          <p></p>
          <p></p>
          <p></p>
          <p></p>
          <p></p>
          <p></p>
          <p></p>
          <p></p>
          <p></p>
          <p></p>
          <p></p>
          <p></p>
          <p></p>
        </div>
        <span>Estamos trabalhando nisso, dê-nos um momento</span>
        {/*<div className="wrapper">
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="shadow"></div>
            <div className="shadow"></div>
            <div className="shadow"></div>
            
        </div>*/}
      </div>

      <img id="holding_img" src="/image.svg"></img>

      <div id="svg-container"></div>

      <div id="configurator">

        <div id="warning">
          <div id="warning-panel">
            <span>AVISO</span>
            <div>
              <button id="btn-1">1</button>
              <button id="btn-2">2</button>
              <button id="btn-3">Cancelar</button>
            </div>
          </div>
        </div>

        <div id="current-cloth">
          <span>MALE - SOCCER</span>
        </div>

        <div id="upload-icon-warning">
          Click where you want to place the image on the cloth
        </div>

        <div id="modal-text-iframe">
          <div className='box-modal-text-iframe'>
            <input id="txt-input-iframe" type='text'></input>
            <div className='box-buttons-modal-text-iframe'>
              <button id="close-modal-text-iframe">CANCELAR</button>
              <button id="insert-text-iframe">ADICIONAR</button>
            </div>
          </div>
        </div>

        <div id="toolpanel-iframe" className='toolpanel'>
          <div id="state-toolpanel-iframe">
            <button id="state-toolpanel-iframe-btn">
              <i id="icon-expand-elem" className="fas fa-chevron-up"></i>
            </button>
          </div>
          
          {activeSelection && <SelectionSettingsIframe canvas={canvas} activeSelection={activeSelection} />}
        </div>

        <div id="panel-save-iframe">
          <div id="box-panel-save">
            <span>Save the changes and send the edited art to review? You can only do this once</span>
            <div id="box-buttons-panel-save">
              <button id="close-panel-save">CANCEL</button>
              <button id="send-art">SEND</button>
            </div>
          </div>
        </div>
        

        <Toolbar activeTool={activeTool} id="toolbar-configurator">
            <div id="colorsButtonsIframe">

              <p className="title-iframe">{__('COLORS PATTERNS')}</p>

              <div className='button-iframe'>
                <button id="backColor">
                  <i className="fas fa-arrow-left"></i>
                </button>
                <span>back</span>
              </div>

              <div className='button-iframe'>
                <button id="addcolorsvg">
                  <i className="fas fa-sync-alt"></i>
                </button>
                <span>update</span>
              </div>

              <div className='button-iframe'>
                <button id="random-colors">
                  <i className="fas fa-random"></i>
                </button>
                <span>random</span>
              </div>

              <div id="colorsPanel"></div>
            </div>
            
            <button className='btn-iframe' id="svg-editor-iframe">
                <i className="fas fa-edit"></i>
            </button>

            <button className='btn-iframe-editor' id="back-main">
                <i className="fas fa-arrow-left"></i>
            </button>
            {/**<button className='btn-iframe-editor' id="controls-state">
              <i className="fas fa-unlock-alt"></i>
            </button> */}
            <button className='btn-iframe-editor' id="iframe-colors">
                <i className="fas fa-palette"></i>
            </button>
            <button className='btn-iframe-editor' id="">
                <i className="fas fa-upload"></i>
                <input type='file' id="upload-img-iframe" accept="image/*"></input>
            </button>
            <button className='btn-iframe-editor' id="add-text-iframe">
                <i className="fas fa-font"></i>
            </button>


            <button className='btn-iframe-customize' id="back-customize">
              <i className="fas fa-arrow-left"></i>
            </button>
            <button className='btn-iframe-customize' id="state-body">
              <i className="fas fa-male"></i>
            </button>
            <button className='btn-iframe-customize' id="state-socks">
              <i className="fas fa-socks"></i>
            </button>
            <button className='btn-iframe-customize' id="state-hat">
              <i className="fas fa-hat-cowboy"></i>
            </button>
            <button className='btn-iframe-customize' id="state-hair">
              <i className="fas fa-ethernet"></i>
            </button>
            <button className='btn-iframe-customize' id="state-shoes">
              <i className="fas fa-shoe-prints"></i>
            </button>
            <button className='btn-iframe-customize' id="state-mask">
              <i className="fas fa-mask"></i>
            </button>
            <button className='btn-iframe-customize' id="state-glasses">
              <i className="fas fa-glasses"></i>
            </button>
            <button className='btn-iframe-customize' id="state-expressions">
              <i className="far fa-grin-alt"></i>
            </button>
            <button className='btn-iframe-customize' id="state-animation">
              <i className="fas fa-play"></i>
            </button>
            <button className='btn-iframe-customize' id="state-background">
              <i className="fas fa-image"></i>
            </button>
            <button className='btn-iframe-customize' id="state-annotations">
              <i className="fas fa-list-ol"></i>
            </button>

            {/**<input min="0.1" max="2" id="scale-selected" step="0.01" className='btn-iframe-editor range-iframe input-iframe' type='range'></input>
            <span className='btn-iframe-editor txt-iframe  input-iframe'>scale</span>

            <input defaultValue="0" min="0" max="360" id="rotate-selected"  step="0.01" className='btn-iframe-editor range-iframe input-iframe' type='range'></input>
            <span className='btn-iframe-editor txt-iframe input-iframe'>rotate</span> */}

            <button className='btn-iframe' id="customize">
                <i className="fas fa-user-edit"></i>
            </button>

            <button className='btn-iframe' id="update-material-3d" title='CHANGE MATERIAL'>
              <i className="fas fa-magic"></i>
            </button>
            {/**<button className='btn-iframe' id="">
                <i className="fas fa-share-alt"></i>
            </button> */}
            
            <button className='btn-iframe open-list' id="" title="LIST" data-panel="panel-list">
                <i className="fas fa-list"></i>
            </button>
            <button className='btn-iframe' id="init-ar">
                <i className="fas fa-vr-cardboard"></i>
            </button>
            <button className='btn-iframe' id="save-svg-anon">
                <i className="fas fa-save"></i>
            </button>
            <button className='btn-iframe' id="">
                <i className="fas fa-question"></i>
            </button>
            <button className='btn-iframe' id="iframe-full">
                <i className="fas fa-expand"></i>
            </button>
          {/**<button id="svg-editor-state"><IconEdit  /></button>
          <Button name="change_sport" title={__('ChangeSport')}><IconSports  /></Button>
          <Button name="background_scene" title={__('BackgroundScene')}><IconWallPaper /></Button>
          <Button name="upload_custom" title={__('UploadCustom')}><IconUpload2 /></Button> 
          <Button name="video" title={__('Video')}><IconVideo  /></Button>
          <Button id={__("animation-side-menu")} name="animation" title={__('Animation')}><IconPlay  /></Button>
          <Button id={__("material-side-menu")} name="material" title={__('Material')} ><IconBoy  /></Button>
          <Button name="cloth" title={__('Cloth')} ><IconCloth /></Button>
          <Button name="download" title={__('Download')} ><IconDownload2 /></Button>
          <Button name="annotation" title={__('Annotation')} ><IconAnnotation /></Button> */}
        </Toolbar>

        <ToolPanel visible={ activeSelection || (
            activeTool !== 'select' && activeTool !== 'line'  && activeTool !== 'path' && activeTool !== 'textbox'
          )}>

          <div className='toolpanel_panel' id="BackgroundScene">
            <BackgroundScene canvas={canvas} />
          </div>

          <div className='toolpanel_panel' id="ChangeSport">
            <ChangeSport canvas={canvas} />
          </div>

          <div className='toolpanel_panel' id="UploadCustom">
            <UploadCustom canvas={canvas} />
          </div>

          <div className='toolpanel_panel' id="Video">
            <Video canvas={canvas} />
          </div>

          <div className='toolpanel_panel' id="Animation">
            <Animation canvas={canvas} />
          </div>

          <div  className='toolpanel_panel' id="Material">
            <Material canvas={canvas} />
          </div>

          <div  className='toolpanel_panel' id="Cloth">
            <Cloth canvas={canvas} />
          </div>

          <div  className='toolpanel_panel' id="Download">
            <Download canvas={canvas} />
          </div>

          <div  className='toolpanel_panel' id="Annotation">
            <Annotation canvas={canvas} />
          </div>
          
        </ToolPanel>

      </div>

      <div id="svg-editor">

        <div id="externalObject">
          <span>Double click on the mesh that you want to edit the map</span>
        </div>

        <Notification notification={notification} setNotification={setNotification} />


        {/**<Header> */}

          {/**<div className="input-select">
            <div className="select" id='select-map'>
              <div className="active-option" id="active-option-map">
              </div>
              <div className="list" id='list-maps'>
                <div className="option option-map">Square</div>
                <div className="option option-map">Round</div>
              </div>
            </div>
          </div> */}

          {/**<Button title={__('Undo')} handleClick={() => undo(canvas, history, setHistory)}
            className={ (!history.index || history.index === 0) ? 'disabled' : '' }><IconUndo /></Button>
          <Button title={__('Redo')} handleClick={() => redo(canvas, history, setHistory)}
            className={ (history.index < (history.states.length - 1)) ? '' : 'disabled' }><IconRedo /></Button> */}

          {/**<div className="separator"></div>

          <button id="update-canvas"><IconClose /></button> 

          <Button title={__('Save (to browser)')} handleClick={ () => {
              saveInBrowser.save('canvasEditor', canvas.toJSON());
              setNotification({ message: __('Project is saved in this browser!'), seconds: 3})
            } }><IconTick /></Button>*/}
          {/**<Button title={__('Download as..')} className="download" handleClick={ () => setDownloadMenuVisible(!downloadMenuVisible) }><IconDownload /></Button>
          <Button title={__('Close and open new')} handleClick={ () => {
              if (window.confirm(__('This will clear the canvas! Are you sure?'))) {
                setHistory({ index: null, states: [] }); canvas.clear(); saveInBrowser.remove('canvasEditor');
              }
            } } className="close"><IconClose /></Button> */}

          {/**<FloatingMenu visible={downloadMenuVisible} setVisible={setDownloadMenuVisible}>
            <Menu handleClick={ () => {
                setZoom(1);
                applyZoom(canvas, 1);
                setDownloadMenuVisible(false);
                downloadSVG(canvas.toSVG());
              } }>{__('Download as SVG')}</Menu>
            <Menu handleClick={ () => {
                setZoom(1);
                applyZoom(canvas, 1);
                setDownloadMenuVisible(false);
                downloadImage(canvas.toDataURL());
              } }>{__('Download as PNG')}</Menu>
            <Menu handleClick={ () => {
                setZoom(1);
                applyZoom(canvas, 1);
                setDownloadMenuVisible(false);
                downloadImage(canvas.toDataURL({ format: 'jpeg' }), 'jpg', 'image/jpeg');
              } }>{__('Download as JPG')}</Menu>
          </FloatingMenu>
        </Header> */}

        <Toolbar activeTool={activeTool}>
          <button id="svg-editor-state-back"><IconArrowBack  /></button>
          {/**<Button name="select" title={__('Select/move object (V)')} handleClick={ () => setActiveTool('select') }><IconCursor /></Button> */}
          <Button name="layers" title={__('layersParent')} handleClick={ () => setActiveTool('layers') }><IconGraph /></Button>
          <Button name="shapes" title={__('Shapes')} handleClick={ () => setActiveTool('shapes') }><IconShape /></Button>
          {/**<Button name="line" title={__('Line')} handleClick={ () => setActiveTool('line') }><IconLine /></Button>
          <Button name="path" title={__('Connectable lines & curves')} handleClick={ () => setActiveTool('path') }><IconPath /></Button>*/}
          <Button name="draw" title={__('Free draw')} handleClick={ () => setActiveTool('draw') }><IconBrush /></Button> 
          <Button name="textbox" title={__('TextBox')} handleClick={ () => setActiveTool('textbox') }><IconText /></Button>
          <Button name="upload" title={__('UploadImage')} handleClick={ () => setActiveTool('upload') }><IconUpload /></Button>
          <Button name="color" title={__('colorsParent')} handleClick={ () => setActiveTool('color') }><IconColorPattern /></Button>
          {/**<div className="separator"></div> */}
          <Button name="background" title={__('Canvas options')} handleClick={ () => setActiveTool('background') }><IconGear /></Button>
          <Button name="patternsParent" title={__('patternsParent')} handleClick={ () => setActiveTool('patternsParent') }><IconPattenrs /></Button>  
        </Toolbar>

        <ToolPanel visible={ activeSelection || (
            activeTool !== 'select' && activeTool !== 'line'  && activeTool !== 'path' && activeTool !== 'textbox'
          )}>

          {activeSelection && <SelectionSettings canvas={canvas} activeSelection={activeSelection} />}

          {activeTool === 'background' && !activeSelection && <CanvasSettings canvas={canvas} />}

          {activeTool === 'draw' && !activeSelection && <DrawSettings canvas={canvas} />}

          {activeTool === 'shapes' && !activeSelection && <Shapes canvas={canvas} />}

          {/**{activeTool === 'layers' && !activeSelection && <Layers canvas={canvas} />} */}

          <div className='toolpanel_panel' id="UploadImage">
            <UploadSettings canvas={canvas} />
          </div>

          <div className='toolpanel_panel' id="TextBox">
            <p className="title">{__('ADD TEXT')}</p>
            <div>
              <p id="txt-text-editor">click on the point in the editor where you want to add the text</p>
              <button id="add-text-editor">ADD TEXT</button>
              <button id="add-curved-text-editor">ADD CURVED TEXT</button>
            </div>
          </div>

          <div id="minimizer-svg-editor"></div>

          {/**<div className='toolpanel_panel' id="LayerParent">
            <LayerParent canvas={canvas} />
          </div> */}

          <div className='toolpanel_panel' id="layersParent">
            <p className="title">{__('CANVAS LAYERS')}</p>
            <div id="panel-graph">
              {/**<button id="btn-up-graph">
                <i className="fas fa-angle-up"></i>
              </button> */}
              <button id="update-graph">
                Update Canvas Layers
              </button>
            </div>
            <div className="folder-tree-wrapper">
              <ul className="folder-tree boxDrag" id="boxDrag"></ul>
              <div id="itemClipDrag" className="itemDrag itemClip hide"></div>
            </div>
          </div>

          <div className='toolpanel_panel' id="patternsParent">
            <p className="title">{__('PATTERNS')}</p>
            <div id="options-patterns"></div>
            <div id="patterns"></div>
          </div>

          {/**{activeTool === 'patterns' && !activeSelection && <Patterns canvas={canvas} />} */}
        </ToolPanel>


        <FabricCanvas canvas={canvas} setCanvas={setCanvas}
          selectionInfo={selectionInfo} setSelectionInfo={setSelectionInfo}
          setActiveSelection={setActiveSelection}
          setHistory={setHistory} />


        <div className="bottom-info">
          <IconZoom />
          <select onChange={(e) => { setZoom(e.target.value); applyZoom(canvas, e.target.value); } } value={zoom}>
            {zoomOptions.map((z, index) => {
              if (index === 0 && !zoomOptions.includes(Number(zoom))) {
                return ( <option key={zoom} value="">{Number(zoom * 100).toFixed(0)}%</option> )
              }

              return (
                <option key={z} value={z}>{Number(z * 100).toFixed(0)}%</option>
              )
            })}
          </select>
        </div>
      </div>
    </div>
  )
}

export default SimulatorIframe

import React, { useState } from 'react';
import './ToolPanel.scss';
import $ from "jquery";

const ToolPanel = (props) => {

  const [minimized, setMinimized] = useState(false)

  let classname = 'toolpanel'
  classname += props.visible ? ' visible' : ''
  classname += minimized ? ' minimized' : ''

  if(!props.visible){
    //console.log("111111111111")
    if ($("#svg-editor").css("display") == "block") {
      window['updateCanvasWidth'](0);
    }
  }

  return (
    <div className={classname}>
      <div className="minimizer" onClick={ () => setMinimized(!minimized) }></div>
      <div className="holder sbar">{props.children}</div>
    </div>
  )

}



export default ToolPanel;

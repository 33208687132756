import React from 'react';
import { fabric } from '../fabric/dist/fabric.js';

import Button from './Button';

const SelectionClipSettings = ({ canvas, activeSelection }) => {

  
  return (
    <div>
        <p>Para aplicar clippath, selecione 2 objetos e clicque no botão abaixo,
          o segundo objeto será usado como mascára para o primeiro objeto.</p>
        <div id="clip-options">
            <button id="apply-clip-path">Aplicar Clip Path</button>
            <button id="remove-clip-path">Remover Clip Path</button>
        </div>
    </div>
  )

}

export default SelectionClipSettings

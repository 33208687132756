import React from 'react';
import './SelectionSettings.scss';

import __ from './../utils/translation';

import SelectionColorSettings from './SelectionColorSettings';
import SelectionBorderSettings from './SelectionBorderSettings';
import SelectionTextSettings from './SelectionTextSettings';
import SelectionAlignSettings from './SelectionAlignSettings';
import SelectionObjectSettings from './SelectionObjectSettings';
import SelectionEffectSettings from './SelectionEffectSettings';
import SelectionControlsSettings from './SelectionControlsSettings';
//
import SelectionClipSettings from './SelectionClipSettings';
import $ from "jquery";

const SelectionSettingsIframe = ({ canvas, activeSelection }) => {

  $("#layersParent").css("display", "none");
  $("#patternsParent").css("display", "none");
  $("#colorsParent").css("display", "none");
  $("#UploadImage").css("display", "none");
  $("#TextBox").css("display", "none");
  $(".toolbar button").removeClass("active");

  return (
    <>
      <p className="title">{__('SELECTION SETTINGS')}</p>
      
      <button id="crop-image">CROP</button>
      <button id="remove-elem-iframe">REMOVE</button>

      {
        <>
        <p className="subtitle">{__('Controls')}</p>
        <SelectionControlsSettings canvas={canvas} activeSelection={activeSelection} />
        </>
      }

      { (activeSelection.type === 'textbox' || activeSelection.type === "text-curved") &&
        <>
        <p className="subtitle">{__('Color')}</p>
        <SelectionColorSettings canvas={canvas} activeSelection={activeSelection} />
        </>
      }


      { (activeSelection.type === 'textbox' || activeSelection.type === "text-curved") &&
        <>
          <p className="subtitle">{__('Font style')}</p>
          <SelectionTextSettings canvas={canvas} activeSelection={activeSelection} />
        </>
      }

    </>
  )
}

export default SelectionSettingsIframe

import { Button } from "baseui/button";
import { Input } from "baseui/input";
import styled from "styled-components";
import {
  HeadingXXLarge,
  HeadingXLarge,
  HeadingLarge,
  HeadingMedium,
  HeadingSmall,
  HeadingXSmall,
} from "baseui/typography";
import {
  Container,
  ErrorText,
  InnerContainer,
  InputWrapper,
  StyledInput,
} from "./components/commons";

import "./login.css";

import { useSignIn } from "react-auth-kit";
import { useFormik } from "formik";
import axios, { AxiosError } from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import {useIsAuthenticated} from 'react-auth-kit';
import { useSignOut } from "react-auth-kit";

import Simulator from "./Simulator.js";
import SimulatorIframe from "./SimulatorIframe.js";

function Login(props: any) {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const signIn = useSignIn();
  const isAuthenticated = useIsAuthenticated();
  const singOut = useSignOut();
  const navigate = useNavigate();

  const logout = () => {
    singOut();
    window.open(window.location.origin + "/", "_self");
    //navigate("/");
  };

  const onSubmit = async (values: any) => {
    setError("");

    try {

      const response = await axios.post(
        "https://api.aistenlab.com/auth/login",
        values
      );

      localStorage.setItem("token", response.data.token);
      localStorage.setItem("user_type", response.data.user.user_type);

      console.log(response)

      signIn({
        token: response.data.token,
        expiresIn: 360000,
        tokenType: "Bearer",
        authState: { email: values.email },
      });

      //window.open(window.location.origin + "/", "_self");
      //setSuccess(true);
    } catch (err) {
      if (err && err instanceof AxiosError)
        setError(err.response?.data.message);
      else if (err && err instanceof Error) setError(err.message);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit,
  });

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  var is_iframe = urlParams.get('i');

  if(is_iframe){
    window.is_iframe = true;
  }else{
    window.is_iframe = false;
  }

  /*if(!window.checkedLog && isAuthenticated()){
    window.checkedLog = true;
    $.ajax({
      url: 'https://dev.aistenlab.com/simulator-projects/quota',
      type: 'GET',
      headers: {
        "Authorization": 'Bearer ' + localStorage.getItem("token")
      },
      success: function (data) {
      },
      error: function (err) {
        if(isAuthenticated()){
          localStorage.clear();
          singOut();
          //window.open(window.location.origin + "/", "_self");
        }
      }
    })
  }*/
  

  return (
    <>
      
      {is_iframe ? (
        <SimulatorIframe/>
      ) : 
      isAuthenticated() ? (
        <Simulator/>
      ) : (

        <div className="Login">

          <div id="logo_login"></div>
          <div className="login-page">
                <div className="form">
                    <form onSubmit={formik.handleSubmit} className="login-form ">
                        <h2>BEM VINDO</h2>
                        <input value={formik.values.email}
                        onChange={formik.handleChange} name="email" type="email" required placeholder="Usuário" />
                        <input value={formik.values.password}
                        onChange={formik.handleChange} name="password" type="password" required placeholder="Senha" />
                        {/**<img src="https://cdn2.iconfinder.com/data/icons/basic-ui-interface-v-2/32/hide-512.png"
                            id="showimg"></img> */}
                        <span id="vaild-pass"></span>
                        {/**<button type="submit">ENTRAR</button> */}

                        <Button size="large" kind="primary" isLoading={formik.isSubmitting}>
                        Login
                        </Button>
                        <p className="message"><a href="#">Esqueceu a senha?</a></p>
                    </form>
                </div>
            </div>

              {/**<Container>
                <InnerContainer>
                  <form onSubmit={formik.handleSubmit}>
                    <HeadingXXLarge>Bem Vindo de Volta!</HeadingXXLarge>
                    <ErrorText>{error}</ErrorText>
                    <InputWrapper>
                      <StyledInput
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        placeholder="Email"
                        clearOnEscape
                        size="large"
                        type="email"
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <StyledInput
                        name="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        placeholder="Senha"
                        clearOnEscape
                        size="large"
                        type="password"
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <Button size="large" kind="primary" isLoading={formik.isSubmitting}>
                        Login
                      </Button>
                    </InputWrapper>
                  </form>
                </InnerContainer>
              </Container> */}
              <div id="back"></div>
              {/*<div id="divVideo">
                  <video id="video" loop autoPlay={true} muted={true}>
                      <source id="mp4" type="video/mp4" />
                  </video>
      </div>*/}
          </div>
            )}
        </>
  );
}

export { Login };

/*
 * The logic behind textbox drawing.
 */

import __ from './../utils/translation';
import $ from "jquery";

const textBoxDrawing = (fabricCanvas, fabric) => {

  let isDrawingText = false, textboxRect, origX, origY, pointer;

  let addingText = false, curved = false;;

  window.addingText = false;
  window.curved = false;

  $("body").on('click', '#add-text-editor, #add-curved-text-editor', function () {
    window.curved = false;
    //
    $("#add-text-editor").css("display", "none");
    $("#add-curved-text-editor").css("display", "none");
    $("#txt-text-editor").css("display", "flex");
    //
    fabricCanvas.isDrawingTextMode = true
    fabricCanvas.defaultCursor = 'crosshair'
    fabricCanvas.selection = false
    fabricCanvas.forEachObject(o => {
      o.selectable = false
      o.evented = false
    });
    window.addingText = true;

    if($(this).attr("id") == "add-curved-text-editor"){
      window.curved=true;
    }
  });

  fabricCanvas.on('mouse:down', (o) => {
    if (! window.addingText) return;
    //
    pointer = fabricCanvas.getPointer(o.e);
    origX = pointer.x;
    origY = pointer.y;
    // get final rect coords and replace it with textbox
    let textbox;
    if(window.curved){
      textbox = new fabric.TextCurved(__($("#txt-input-iframe").val()), {
        left: origX,
        top: origY,
        width: 500,
        fontSize: 200,
        fontFamily:  "'Open Sans', sans-serif",
        diameter: 500,
        kerning: 0,
        originX: 'center',
        originY: 'center',
      });
      //textbox.type = "curved-text";
      textbox.styles = {};
    }else{
      textbox = new fabric.Textbox(($("#txt-input-iframe").val()), {
        left: origX,
        top: origY,
        width: 180,
        fontSize: 16,
        fontFamily: "Arial",
        textAlign: 'center',
        scaleX: 10,
        scaleY: 10,
        originX: 'center',
        originY: 'center',
      });
    }
    //fabricCanvas.remove(textboxRect);
    setTimeout(function () {
      console.log(textbox)
      textbox.set("width",(textbox.__lineWidths[0] + 10));
      fabricCanvas.renderAll();
    }, 100);
    
    fabricCanvas.add(textbox).setActiveObject(textbox)
    textbox.setControlsVisibility({ 'mb': false });
    fabricCanvas.trigger('object:modified');
    window.addingText = false;
    // reset all modes
    fabricCanvas.set('isDrawingMode', false)
    fabricCanvas.set('isDrawingLineMode', false)
    fabricCanvas.set('isDrawingPathMode', false)
    fabricCanvas.set('isDrawingTextMode', false)
    fabricCanvas.defaultCursor = 'default'
    fabricCanvas.selection = true
    fabricCanvas.forEachObject(o => {
      o.selectable = true
      o.evented = true
    });
    $("#add-text-editor").css("display", "block");
    $("#add-curved-text-editor").css("display", "block");
    $("#txt-text-editor").css("display", "none");

    //TEXT BOX ADJUST WIDTH
    var left = origX;
    console.log("Iiiiiiiiiiiiiiiiiiiiii")

    textbox.on(("changed"),function(){
      //canvas.setActiveObject(textBox);
      setTimeout(function () {
      var actualWidth = textbox.scaleX * textbox.width;
       var largest = fabricCanvas.getActiveObject().__lineWidths[0];
       var tryWidth = (largest + 10);
       
       //console.log(fabricCanvas.getActiveObject().__lineWidths)
       /*console.log("11111111111111111")
       console.log(textbox.left)
       console.log(actualWidth)
       console.log(fabricCanvas.width)
       console.log(largest)
       console.log(tryWidth)*/
       
       fabricCanvas.getActiveObject().set("width",tryWidth);
       if((textbox.left + actualWidth) >= fabricCanvas.width - 10)
        {
            //console.log(canvas.height - arrowLeft)
            //textbox.set("width", fabricCanvas.width - left - 10)
       }
       fabricCanvas.renderAll();
      }, 100);
      
    });

    textbox.on(("modified"),function(){
      left = textbox.left;
      console.log("textbox modified");
      fabricCanvas.renderAll();
    });
  });

  //


  /*
  fabricCanvas.on('mouse:down', (o) => {
    if (! fabricCanvas.isDrawingTextMode) return;

    isDrawingText = true;
    pointer = fabricCanvas.getPointer(o.e);
    origX = pointer.x;
    origY = pointer.y;
    textboxRect = new fabric.Rect({
      left: origX,
      top: origY,
      width: pointer.x-origX,
      height: pointer.y-origY,
      strokeWidth: 1,
      stroke: '#C00000',
      fill: 'rgba(192, 0, 0, 0.2)',
      transparentCorners: false
    });
    fabricCanvas.add(textboxRect);
  });


  fabricCanvas.on('mouse:move', (o) => {
    if (! isDrawingText) return;

    pointer = fabricCanvas.getPointer(o.e);

    if(origX > pointer.x){
        textboxRect.set({ left: Math.abs(pointer.x) });
    }

    if(origY > pointer.y){
        textboxRect.set({ top: Math.abs(pointer.y) });
    }

    textboxRect.set({ width: Math.abs(origX - pointer.x) });
    textboxRect.set({ height: Math.abs(origY - pointer.y) });

    fabricCanvas.renderAll();
  });


  fabricCanvas.on('mouse:up', () => {
    if (! isDrawingText) return;

    isDrawingText = false;

    // get final rect coords and replace it with textbox
    let textbox = new fabric.Textbox(__('Your text goes here...'), {
      left: textboxRect.left,
      top: textboxRect.top,
      width: textboxRect.width < 80 ? 80 : textboxRect.width,
      fontSize: 18,
      fontFamily: "'Open Sans', sans-serif"
    });
    fabricCanvas.remove(textboxRect);
    fabricCanvas.add(textbox).setActiveObject(textbox)
    textbox.setControlsVisibility({ 'mb': false });
    fabricCanvas.trigger('object:modified')
  });
   */

}

export default textBoxDrawing

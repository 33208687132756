import React from 'react';
import './SelectionSettings.scss';

import __ from './../utils/translation';

import SelectionColorSettings from './SelectionColorSettings';
import SelectionBorderSettings from './SelectionBorderSettings';
import SelectionTextSettings from './SelectionTextSettings';
import SelectionAlignSettings from './SelectionAlignSettings';
import SelectionObjectSettings from './SelectionObjectSettings';
import SelectionEffectSettings from './SelectionEffectSettings';
import SelectionControlsSettings from './SelectionControlsSettings';
//
import SelectionClipSettings from './SelectionClipSettings';
import $ from "jquery";

const SelectionSettings = ({ canvas, activeSelection }) => {

  $("#layersParent").css("display", "none");
  $("#patternsParent").css("display", "none");
  $("#colorsParent").css("display", "none");
  $("#UploadImage").css("display", "none");
  $("#TextBox").css("display", "none");
  //$(".toolbar button").removeClass("active");

  return (
    <>
      <p className="title">{__('CONFIGURAÇÃO DA SELEÇÃO')}</p>

      {/**activeSelection.type !== 'image' &&
        activeSelection.type !== 'line' &&
        activeSelection.type !== 'activeSelection' &&
        activeSelection.type !== 'group' &&
        activeSelection.type !== 'textbox' && */}

    { !activeSelection.default &&
      <>
      <p className="subtitle">{__('Opções do Objeto')}</p>
      <SelectionObjectSettings canvas={canvas} activeSelection={activeSelection} />
      </>
    }

      {
        <>
        <p className="subtitle">{__('Controles')}</p>
        <SelectionControlsSettings canvas={canvas} activeSelection={activeSelection} />
        </>
      }

    { !activeSelection.default &&
      <>
      <p className="subtitle">{__('Alinhamento')}</p>
      <SelectionAlignSettings canvas={canvas} activeSelection={activeSelection} />
      </>
    }

      {
        <>
        <p className="subtitle">{__('Opção de Cores')}</p>
        <SelectionColorSettings canvas={canvas} activeSelection={activeSelection} />
        </>
      }


      { (activeSelection.type === 'textbox' || activeSelection.type === "text-curved" || activeSelection.text) &&
        <>
          <p className="subtitle">{__('Estilo da Fonte')}</p>
          <SelectionTextSettings canvas={canvas} activeSelection={activeSelection} />
        </>
      }


      { activeSelection.type !== 'group' && !activeSelection.default &&
        <>
          <p className="subtitle">{__('Borda')}</p>
          <SelectionBorderSettings canvas={canvas} activeSelection={activeSelection} />
        </>
      }

      {activeSelection.type === 'image' &&
        <>
          <p className="subtitle">{__('Efeitos')}</p>
          <SelectionEffectSettings canvas={canvas} activeSelection={activeSelection} />
        </>
      }

      {
        <>
        <p className="subtitle">{__('Clip')}</p>
        <SelectionClipSettings canvas={canvas} activeSelection={activeSelection} />
        </>
      }


    </>
  )
}

export default SelectionSettings

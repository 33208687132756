import React, { useState, useEffect } from 'react';
import { fabric } from '../fabric/dist/fabric.js';
import __ from './../utils/translation';
import InputAmount from './InputAmount';
import { InputSelect, Option } from './InputSelect';
import delayedAction from './../utils/delayedAction';

import Button from './Button';

const SelectionControlsSettings = ({ canvas, activeSelection }) => {


    const [rotation, setRotation] = useState(0)
    const [scale, setScale] = useState(1)

    const handleRotationChange = (value) => {

        setRotation(value);

        activeSelection.set({
            angle: value
        });

        canvas.renderAll();
        window['updateCanvas']();
      
        delayedAction(1200, () => {
            canvas.trigger('object:modified')
        })
    }

    const handleScaleChange = (value) => {

        setScale(value)

        activeSelection.scale(parseFloat(value)).setCoords();

        canvas.renderAll()
        window['updateCanvas']();
      
        delayedAction(1200, () => {
            canvas.trigger('object:modified')
        })
    }

    /*const handleScaleChange = (action, amount) => {
        
        let scale = 0

        if (action === 'decrease') {
            scale = effects.scale
            scale = scale ===  1 ? 1 : scale - 1
        }

        if (action === 'increase') {
            scale = effects.scale + 1
        }

        if (action === 'change') {
            scale = parseInt(amount)
            if (!Number.isInteger(scale)) return
        }

        if(scale < 1){
            scale = 1;
        }

        setEffects({...effects,
            scale: scale
        })

        activeSelection.scale(parseFloat(scale)).setCoords();

        canvas.renderAll()
        canvas.trigger('object:modified')
    }*/

    // on load get active selection font settings
    useEffect(() => {
        if (!activeSelection) return //|| !activeSelection.getSelectionStyles

        if(activeSelection.angle)
            setRotation(activeSelection.angle)

        if(activeSelection.scaleX)
            setScale(activeSelection.scaleX)

    }, [activeSelection])
  
  return (
    <div>
        <div className="setting">
            <div className="label">{__('Rotação')}</div><div className="function">
            <input type="range" min="0" max="360" steps="1"
                onChange={ (e) => handleRotationChange(e.target.value) }
                value={ rotation } />
            </div>
        </div>
        <div className="setting">
            <div className="label">{__('Escala')}</div><div className="function">
            <input type="range" min="0.1" max="10" step="0.01"
                onChange={ (e) => handleScaleChange(e.target.value) }
                value={ scale } />
            </div>
        </div>
        {/*<div className="setting">
            <div className="label">Scale</div><div className="function">
            <InputAmount unit="" value={effects.scale}
                handleDecrease={() => handleScaleChange('decrease')}
                handleChange={(e) => handleScaleChange('change', e.target.value)}
                handleIncrease={() => handleScaleChange('increase')}
                />
            </div>
        </div>*/}
    </div>
  )

}

export default SelectionControlsSettings

import React, { useState, useEffect } from 'react';
import './Annotation.scss';
import __ from './../utils/translation';
import $ from "jquery";
import { downloadImage, downloadSVG } from '../utils/downloadImage';
import { applyZoom, zoomWithKeys, zoomWithMouse, zoomOptions } from '../utils/zoom';
import { ReactComponent as IconDownload2 } from '../icons/file_download.svg';

const Clone = ({ canvas }) => {

    

    return (
        <>
            <p className="title">{__('CLONE')}</p>

            <div id="option-clone">
                <button id="state-clone">Estado Clone</button>
                <button id="state-trans">Estado Transform</button>
                <button id="state-pos">Modo Posição</button>
                <button id="state-rot">Modo Rotação</button>
            </div>
            
        </>
    )
}

export default Clone
import React, { useRef, useState } from 'react';
import './UploadSettings.scss';
import __ from './../utils/translation';
import { ReactComponent as IconSadSmiley } from './../icons/sad-smiley.svg';
import $ from "jquery";
import { InputSelect, Option } from './InputSelect';

const UploadCustom = ({ canvas }) => {

  const inputFile = useRef(null)
  const dropArea = useRef(null)

  var type = "obj";

  const [drawSettings, setDrawSettings] = useState({
    style: 'pos'
  })


  // check if HTML5 File API is supported by the browser
  if (!window.File && !window.FileReader && !window.FileList && !window.Blob) {
    return (
      <div className="file-upload no-support">
        <p>{__(`Your browser doesn't support file upload. Please upgrade to a modern browser, for example:`)} <a href="https://www.google.com/chrome/" target="_blank" rel="noopener noreferrer">Chrome!</a></p>
        <p><IconSadSmiley /></p>
      </div>
    )
  }


  const processFiles = (e, file) => {
    e.preventDefault()
    dropArea.current.style.backgroundColor = '#fff';
    window['loadFile'](file[0], type);
  }

  $("body").on('click', '.upload-type', function () {
    $(".upload-type").css("text-decoration", "none");
    $(this).css("text-decoration", "underline");
    type = $(this).data("type");

    $(".file-upload").css("display", "block");
    $("#options-lista").css("display", "none");

    if(type == "obj"){
      $("#options-scene").css("display", "none");
      $("#text-upload").text("Carregue um objeto para editar os mapas no editor SVG.");
    }else if(type == "scene"){
      $("#options-scene").css("display", "block");
      $("#text-upload").text("Suba um objeto para adicionar à cena para servir como decoração, você não poderá editar os mapas deste objeto no editor svg.");
    }else{
      $("#options-scene").css("display", "none");
      $("#options-lista").css("display", "block");
      $(".file-upload").css("display", "none");
    }
  });

  const handleSceneTransform = (style) => {

    window['transformType'](style);

    setDrawSettings({
      ...drawSettings,
      style: style
    })
  }

  const deleteScene = () => {
    window['deleteFile']();
  }

  const resetTransform = (type) => {
    window['resetTransform'](type);
  }

  return (
    <>
      <p className="title">{__('UPLOAD')}</p>

      <div className='options'>
        <p id="upload-object" data-type="obj" className="subtitle upload-type">{__('Objeto')}</p>
        <p id="upload-scene" data-type="scene" className="subtitle upload-type">{__('Cena')}</p>
        <p id="upload-list" data-type="lista" className="subtitle upload-type">{__('Lista')}</p>
      </div>

      <div className="file-upload">

        <p id="text-upload">{__(`Carregue um objeto para editar os mapas no editor SVG.`)}</p>
        <br></br>
        <p>{__(`O upload do arquivo ocorre por meio da API de arquivo HTML5, portanto, não usamos o servidor para armazenar imagens do usuário. Você também pode copiar e colar uma imagem da área de transferência!`)}</p>

        <div className="drop" ref={dropArea} onClick={() => inputFile.current.click()}
          onDrop={(e) => processFiles(e, e.dataTransfer.files)}
          onDragOver={(e) => {
            e.preventDefault()
            dropArea.current.style.backgroundColor = '#ddd'
          }}
          onDragLeave={(e) => {
            e.preventDefault()
            dropArea.current.style.backgroundColor = '#fff'
          }}>
          <div className="info">{__('Arraste e solte arquivos')}<br />{__('ou clique para navegar.')}<br />{__('Apenas arquivos GLB!')}</div>
        </div>

        <input type="file" onChange={(e) => processFiles(e, inputFile.current.files)} accept=".glb"
          ref={inputFile} style={{display: 'none'}} />

      </div>

      <div id="options-lista">
          <div className='scene-up' id="scene1" 
          data-posx="0" data-posy="-0.7" data-posz="3.4"
          data-rotx="0" data-roty="0" data-rotz="0"
          data-scale="0.95"></div>

          <div className='scene-up' id="scene2" 
          data-posx="0" data-posy="0" data-posz="-0.25"
          data-rotx="0" data-roty="0" data-rotz="0"
          data-scale="0.41"></div>

          <div className='scene-up' id="scene3" 
          data-posx="0" data-posy="-1.24" data-posz="-4.5"
          data-rotx="0" data-roty="0" data-rotz="0"
          data-scale="1.11"></div>

          <div className='scene-up' id="scene4" 
          data-posx="0" data-posy="0.22" data-posz="0"
          data-rotx="0" data-roty="-1.434" data-rotz="0"
          data-scale="0.62"></div>

          <div className='scene-up' id="scene5" 
          data-posx="0.189" data-posy="0" data-posz="5.158"
          data-rotx="0" data-roty="-1.53" data-rotz="0"
          data-scale="1.09"></div>

          <div className='scene-up' id="scene6" 
          data-posx="0" data-posy="0.045" data-posz="2.12"
          data-rotx="0" data-roty="0" data-rotz="0"
          data-scale="0.402"></div>

          <div className='scene-up' id="scene7" 
          data-posx="0" data-posy="-0.59" data-posz="3.67"
          data-rotx="0" data-roty="0" data-rotz="0"
          data-scale="0.46"></div>

          <div className='scene-up' id="scene8" 
          data-posx="0.35" data-posy="-0.36" data-posz="4.11"
          data-rotx="0" data-roty="-1.47" data-rotz="0"
          data-scale="0.478"></div>

          <div className='scene-up' id="scene9" 
          data-posx="0" data-posy="-0.37" data-posz="0"
          data-rotx="0" data-roty="0" data-rotz="0"
          data-scale="1.287"></div>

          <div className='scene-up' id="scene10" 
          data-posx="0.044" data-posy="0" data-posz="3.67"
          data-rotx="0" data-roty="0" data-rotz="0"
          data-scale="1.15"></div>

          <div className='scene-up' id="scene11" 
          data-posx="-0.01" data-posy="0.01" data-posz="0.14"
          data-rotx="0" data-roty="0" data-rotz="0"
          data-scale="0.959"></div>

          <div className='scene-up' id="scene12" 
          data-posx="0" data-posy="0" data-posz="-0.11"
          data-rotx="0" data-roty="0" data-rotz="0"
          data-scale="1.28"></div>
      </div>

      <div id="options-scene">
        <button onClick={() => deleteScene()}>{__('Delete Scene')}</button>
        <div className="setting">
          <div className="label">{__('SCene Controls')}</div><div className="function">
            <InputSelect activeOption={drawSettings.style} handleChange={(style) => handleSceneTransform(style)}>
              <Option value="pos">{__('Position')}</Option>
              <Option value="rot">{__('Rotation')}</Option>
              <Option value="scale">{__('Scale')}</Option>
              <Option value="none">{__('None')}</Option>
            </InputSelect>
          </div>
        </div>

        <div className="label">Reset</div>
        <div id="transform-options">
          <button onClick={() => resetTransform('pos')}>{__('Pos.')}</button>
          <button onClick={() => resetTransform('rot')}>{__('Rot.')}</button>
          <button onClick={() => resetTransform('esc')}>{__('Sca.')}</button>
        </div>
      </div>
    </>
  )
}


export default UploadCustom
